<template>
    <div class="modal" @click="handleClose">
        <div class="modal_content" @click.stop="changeInside"
            :style="[{
        minHeight: `${ModalBase.boxHight}`,
        width: `${ModalBase.boxWidth}`
    }]">
            <div style="width: 68px;height: 68px;margin: 0 auto;">
              <img style="width: 100%;height: 100%;" src="https://image.giantgocloud.com/www/ImageMapping/image/20241129/D2F2E5B91DE84577A40DE5F89F8CCF2B.png" alt="">
            </div>
            <div style="font-size: 1vmax;text-align: center;">加载中...</div>
        </div>
    </div>
</template>
<script>
export default {
    components: {

    },
    data() {
        return {

        }
    },
    props: {
        // animationState: {
        //     type: Boolean,
        //     required: true
        // },
        ModalBase: {
            type: Object,
            required: true
        }
    },
    created() {
    },
    methods: {
        handleClose() {
            this.$emit('handleClose')
        },
        changeInside(e) {
            this.$emit('changeInside')
        }
    }
}
</script>
<style lang='scss' scoped>
.modal {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 9998;

    .modal_content {
        position: relative;
        overflow-y: auto;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 20px 20px;
        border-radius: 20px;
        // background-color: #fff;
    }
}

.animation-on {
    animation: animationX 0.5s ease-in-out 1;
}

.animation-off {
    animation: animationY 0.5s ease-in-out 1;
}

@keyframes animationX {
    0% {
        transform: translate(-50%, -50%) scale(0.1);
    }

    100% {
        transform: translate(-50%, -50%) scale(1);
    }
}

@keyframes animationY {
    0% {
        transform: translate(-50%, -50%) scale(1);
    }

    100% {
        transform: translate(-50%, -50%) scale(0.1);
    }
}
</style>
