<template>
  <div>
    <div>
      <div class="pageSize">

        <Head bgColor="#C8EBED"></Head>
        <div class="analysisReport_box2">
          <div class="analysisReport_box2_info2">
            <div class="analysisReport_box5_content_info_title">
              五、生理年龄分析：
            </div>
            <div class="analys_age">
              <div class="analys_age_item" v-if="physicAge">
                <div class="analys_age_item_left">
                  <div>生理年龄</div>
                  <div style="padding-top: 12px;">{{ physicAge }} 岁</div>
                </div>
                <div class="analys_age_item_right">
                  <img
                    src="https://image.giantgocloud.com/www/ImageMapping/image/20240807/6052861BF23E4BA2ADBCC627CC571B85.png"
                    class="wh100">
                </div>
              </div>
              <div class="analys_age_item_none" v-else>
                <div class="analys_none_left">
                  <img
                    src="https://image.giantgocloud.com/www/ImageMapping/image/20240807/01457DC1F0C541ED950FD069A8F52F5C.png"
                    class="wh100">
                </div>
                <div class="analys_none_right">
                  未上传指标
                </div>
                <div></div>
              </div>
            </div>
          </div>
          <div class="analysisReport_box5_content_info_title">
            六、七大生理失衡：
          </div>
          <template v-if="objData.unBlanceList && objData.unBlanceList?.length > 0">
            <div class="box2_info_ul">
              <template v-for="(item, index) in objData.unBlanceList">
                <div class="box2_info_ul_li" v-if="index < 3">
                  <div class="box2_info_ul_li_text">
                    <div class="box2_info_ul_li_name">
                      {{ item.unBalanceTitle }}
                    </div>
                  </div>
                  <div class="box2_info_ul_li_item" v-for="(item1, index1) in item.balanceDetail" :key="index1"
                    :style="{ color: `${item1.colorCode}` }">
                    {{ item1.subUnBalanceTitle }}
                  </div>
                </div>
              </template>
            </div>
            <div class="box2_info_ul">
              <template v-for="(item, index) in objData.unBlanceList">
                <div class="box2_info_ul_li" v-if="index >=3">
                  <div class="box2_info_ul_li_text">
                    <div class="box2_info_ul_li_name">
                      {{ item.unBalanceTitle }}
                    </div>
                  </div>
                  <div class="box2_info_ul_li_item" v-for="(item1, index1) in item.balanceDetail" :key="index1"
                    :style="{ color: `${item1.colorCode}` }">
                    {{ item1.subUnBalanceTitle }}
                  </div>
                </div>
              </template>
            </div>
          </template>
          <template v-else>
            <div class="null_data">暂无数据</div>
          </template>
          <div class="analysisReport_box2_info3">
            <div class="analysisReport_box5_content_info_title" style="width: 340px;">
              七、全身系统/器官健康等级分析：
            </div>
            <template v-if="showData.length > 0">
                <div class="Level_Analyzed">
                  <div class="title_top_r">
                    <img src="https://image.giantgocloud.com/www/ImageMapping/image/20240927/4A442A5CAFE34F4EAE9B739CD123EA91.png"
                      class="wh100" alt="">
                  </div>
                  已评估
                </div>
            <div class="orgin_content">
                <span v-for="(item, index ) in showData" :key="index">
                    <div class="orgin_list" v-if="item.colorLevel" >
                      <div class="orgin_strong">
                        <div class="orgin_left" style="position: relative;" :style="'border:1px solid' + item.colorCode">
                          <div class="orgin_left_round" :style="{ background: item.colorCode }"></div>
                          <div class="orgin_left_text" style="color: #0a1721;"> {{ item.organName }}</div>
                        </div>
                        <div style="position: relative;height: 50px;" class="a_c">
                          <div class="orgin_right" :style="`background:${item.colorCode}`">
                            <span style="color: #fff;">
                              {{ item.colorLevel }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
              </span>
            </div>

            <div class="Level_Analyzed">
                  <div class="title_top_r">
                    <img src="https://image.giantgocloud.com/www/ImageMapping/image/20240927/4A442A5CAFE34F4EAE9B739CD123EA91.png"
                      class="wh100" alt="">
                  </div>
                  未评估
                </div>
            <div class="orgin_content">
                <span v-for="(item, index ) in showData" :key="index">
                    <div class="orgin_list" v-if="!item.colorLevel" >
                      <div class="orgin_strong">
                        <div class="orgin_left" style="position: relative;" :style="'border:1px solid' + item.colorCode">
                          <div class="orgin_left_round" :style="{ background: item.colorCode }"></div>
                          <div class="orgin_left_text" style="color: #0a1721;"> {{ item.organName }}</div>
                        </div>
                        <div style="position: relative;height: 50px;" class="a_c">
                          <div class="orgin_right" :style="`background:${item.colorCode}`">
                            <span style="color: #fff;">
                              {{ item.colorLevel }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
              </span>
            </div>
            </template>
            <template v-else>
                <div class="analys_age_item_none">
                  <div class="analys_none_left">
                    <img
                      src="https://image.giantgocloud.com/www/ImageMapping/image/20240807/01457DC1F0C541ED950FD069A8F52F5C.png"
                      class="wh100">
                  </div>
                  <div class="analys_none_right">
                    未上传指标
                  </div>
                  <div></div>
                </div>
              </template>
          </div>
        </div>
        <div class="analysisReportPage" style="position: absolute;right: 60px;bottom: 60px;font-size: 20px;color: #000">
          3 of {{ pageAllNum }}
        </div>
      </div>
      <Bottom />
    </div>
  </div>
  </div>
</template>
<script>
import Head from './head.vue'
import Bottom from './bottom.vue'
export default {
  props: ['objData', 'userInfo', 'pageAllNum'],
  components: {
    Head, Bottom
  },
  name: 'kkk',
  data() {
    return {
      showData: [],
      physicAge: ''
    }
  },
  watch: {
    objData: {
      immediate: true,
      deep: true, // 开启深度监听 专门对付复杂数据类型
      handler: function (newValueA, oldValue) {
        // console.log('newValueA789654444', newValueA)
        this.showData = newValueA.pdfOrganModelList
        this.physicAge = newValueA.physicAge
        // console.log('showData', this.showData);
      }
    }
  },
  methods: {

    hexToRgb(hex, opt) {
      // Remove "#" symbol from the beginning of the string
      hex = hex.replace('#', '')
      // Convert each pair of characters to decimal values for red, green, and blue
      var red = parseInt(hex[0] + hex[1], 16)
      var green = parseInt(hex[2] + hex[3], 16)
      var blue = parseInt(hex[4] + hex[5], 16)
      return 'rgba(' + red + ',' + green + ',' + blue + ',' + opt + ')'
    }
  }
}
</script>

<style lang="scss" scoped>
  .analys_age_item_none {
        width: 223px;
        height: 85px;
        background: #F0FFFD;
        border-radius: 9px;
        border: 1px solid #7FC1C3;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 21px;
    margin-top: 20px;
        .analys_none_left {
          width: 33px;
          height: 30px;
        }

        .analys_none_right {
          font-family: Source Han Sans CN;
          font-size: 28px;
          color: #7FC1C3;
        }
      }
.Level_Analyzed {
  position: relative;
  width: 140px;
  height: 36px;
  line-height: 36px;
  background-color: #EBEBEC;
  font-size: 26px;
  margin-top: 30px;
  border-radius: 6px;
  padding-left: 34px;
  .title_top_r {
    position: absolute;
    left: 6px;
    top: -6px;
    width: 28px;
    height: 28px;
    margin-right: 46px;
  }
}
.analysisReport_box2 {
  width: 974px;
  margin: 0 auto;

  .analysisReport_box2_info1 {
    padding-top: 83px;

    .analysisReport_box2_info1_title {
      position: relative;
      font-weight: 400;
      font-size: 34px;
      color: #5CAAAA;

      &::after {
        content: "";
        position: absolute;
        bottom: -6px;
        left: 0px;
        width: 34px;
        height: 1px;
        background: #5CAAAA;
        z-index: 1;
      }
    }

    .analysisReport_box2_info1_content {
      font-size: 20px;
      margin-top: 10px;
      color: #474F57;
    }
  }

  .analysisReport_box5_content_info_title {
    width: 260px;
    font-weight: 400;
    font-size: 20px;
    color: #474F57;
    border: 1px solid #474F57;
    border-radius: 6px;
    margin-top: 30px;
    padding-left: 10px;
  }

  .null_data {
    color: #474F57;
    font-size: 26px;
    padding-top: 10px;
  }

  .box2_info_ul {
    display: flex;
    flex-wrap: wrap;
    align-items:center;
    margin-top: 20px;
    color: #474F57;
    font-size: 26px;
    .box2_info_ul_li {
      margin-right: 20px;
      margin-bottom: 20px;
      border: 1px solid #474F57;
      width: 270px;
      height: 200px;
      border-radius: 20px;
      .box2_info_ul_li_text {
        display: flex;
        align-items: center;
        // border-radius: 14px;
        padding: 6px 18px;
        border-bottom: 1px solid #474F57;
        .box2_info_ul_li_text_d {
          width: 7px;
          height: 7px;
          background: #D51931;
          border-radius: 50%;
        }

        .box2_info_ul_li_name {
          text-align: center;
          padding-left: 6px;
          font-size: 20px;
        }
      }

      .box2_info_ul_li_item {
        margin-top: 10px;
        font-size: 16px;
        margin-left: 20px;
      }
    }
  }

  .analysisReport_box2_info2 {
    padding-top: 13px;

    .analysisReport_box2_info2_title {
      width: 273px;
      height: 60px;
      background: #7FC4C6;
      border-radius: 14px;
      font-weight: 500;
      font-size: 36px;
      color: #FFFFFF;
      line-height: 14px;
    }

    .analys_age {
      padding-top: 30px;

      .analys_age_item {
        width: 223px;
        height: 85px;
        background: #F0FFFD;
        border-radius: 9px;
        border: 1px solid #7FC1C3;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 21px;

        .analys_age_item_left {
          font-family: Source Han Sans CN;
          font-weight: 600;
          font-size: 20px;
          color: #474F57;
        }

        .analys_age_item_right {
          width: 48px;
          height: 48px;
        }
      }


    }
  }

  .analys_line {
    margin: 0 auto;
    background-color: #474F57;
    width: 1021px;
    height: 0.25px;
    margin-top: 30px;
    opacity: .5;
  }

  .analysisReport_box2_info3 {
    .analysisReport_box2_info2_title {
      width: 490px;
      height: 60px;
      background: #7FC4C6;
      border-radius: 14px;
      font-weight: 500;
      font-size: 36px;
      color: #FFFFFF;
      line-height: 14px;
    }

    .orgin_content {
      display: flex;
      flex-wrap: wrap;

      .analys_age_item_none {
        width: 223px;
        height: 85px;
        background: #F0FFFD;
        border-radius: 9px;
        border: 1px solid #7FC1C3;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 21px;

        .analys_none_left {
          width: 33px;
          height: 30px;
        }

        .analys_none_right {
          font-family: Source Han Sans CN;
          font-size: 28px;
          color: #7FC1C3;
        }
      }
    }

    .orgin_list {
      margin-top: 16px;
      margin-right: 110px;

      .orgin_strong {
        display: flex;
        align-items: center;

        .orgin_left {
          display: flex;
          align-items: center;
          width: 160px;
          height: 40px;
          // background: #FEF9E8;
          border-radius: 13px;
          color: #474F57;
          font-size: 26px;
          padding-left: 19px;

          .orgin_left_round {
            width: 6px;
            height: 6px;
            background: #474F57;
            border-radius: 50%;
          }

          .orgin_left_text {
            padding-left: 10px;
            font-size: 20px;
          }
        }

        .orgin_right {
          width: 36px;
          height: 34px;
          margin-left: 17px;
          // background: #FEF9E8;
          border-radius: 6px;
          // border: 0px solid #EEC122;
          color: #474F57;
          font-family: Bahnschrift;
          font-weight: normal;
          font-size: 20px;
          text-align: center;
          line-height: 38px;
        }

        .orgin_rightA {
          width: 35px;
          height: 35px;
          border-radius: 50%;

          .orgin_rightA_Y {
            width: 20px;
            height: 20px;
            border-radius: 50%;
          }
        }
      }

      .orgin_sub {
        display: flex;
        // flex-wrap: wrap;
        align-items: center;
        font-size: 26px;
        padding-top: 10px;
        color: #474F57;

        .orgin_sub_round {
          width: 6px;
          height: 6px;
          background: #474F57;
          border-radius: 50%;
          margin-left: 16px;
        }

        .orgin_sub_text {
          display: flex;
          flex-wrap: wrap;
          width: 350px;
          padding-left: 10px;
          font-size: 20px;
        }
      }
    }
  }
}

.analysisReport_box11_S6_ul {
  margin-top: 20px;

  .analysisReport_box11_S6_li {
    display: flex;
    align-items: center;
    margin-top: 10px;
    font-size: 20px;

    .analysisReport_box11_S6_li_A {
      display: flex;
      align-items: center;
      width: 200px;
      height: 50px;
      margin-right: 10px;
    }

    .analysisReport_box11_S6_li_B {
      display: flex;
      align-items: center;
      width: 230px;
      height: 59px;
      border-radius: 15px;
      border: 1px solid red;
      margin-right: 10px;

      .analysisReport_box11_S6_li_B_d {
        margin: 0 13px 0 23px;
        width: 7px;
        height: 7px;
        background: #0d1720;
        border-radius: 50%;
      }
    }
  }
}
</style>
