var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.dataObj && _vm.dataObjA)?_c('div',{staticClass:"analysisReport"},[_c('Home',{attrs:{"logoPicture":_vm.dataObj.logoPicture,"userInfo":_vm.dataObj.userInfo,"sickName":_vm.dataObj.riskAnalysisModel.itemName}}),_c('Cover2',{attrs:{"pageAllNum":_vm.pageAllNum,"imgUrl":"https://image.giantgocloud.com/www/ImageMapping/image/20241112/1AA23202FAE546659E743A769D97F543.jpg"}}),_c('Pages2',{attrs:{"pageAllNum":_vm.pageAllNum,"userInfo":_vm.dataObj.userInfo,"objData":_vm.dataObj}}),_c('Pages2_1',{attrs:{"pageAllNum":_vm.pageAllNum,"userInfo":_vm.dataObj.userInfo,"objData":_vm.dataObj}}),_c('Tree',{attrs:{"pageAllNum":_vm.pageAllNum,"objData":_vm.dataObj}}),_c('pages2A',{attrs:{"pageAllNum":_vm.pageAllNum,"pageNum":5,"objData":_vm.dataObj.pdfModelList[0]}}),_c('Pages5',{attrs:{"pageAllNum":_vm.pageAllNum,"pageNum":6,"objData":_vm.dataObj.singleAnalysisInModel.singleAnalysisListForSick[0],"titleA":_vm.dataObj.singleAnalysisInModel.singleAnalysisListForSick[0].healthTrouble
        .projectName + '主题分析',"titleB":'根据你的上传数据分析，以下是以功能医学疾病机制为科学依据对您关注的' +
      _vm.dataObj.singleAnalysisInModel.singleAnalysisListForSick[0].healthTrouble
        .projectName +
      '健康主题进行分析的结果'}}),(_vm.dataObj.pdfModelList?.length == 2)?[_c('pages2A',{attrs:{"pageAllNum":_vm.pageAllNum,"pageNum":7 + _vm.setNum1,"objData":_vm.dataObj.pdfModelList[1]}}),_c('Pages6',{attrs:{"pageAllNum":_vm.pageAllNum,"pageNum":8 + _vm.setNum1,"objData":_vm.dataObj.singleAnalysisInModel.singleAnalysisListForSick[1],"titleA":_vm.dataObj.singleAnalysisInModel.singleAnalysisListForSick[1]
          .healthTrouble.projectName + '主题分析',"titleB":'根据你的上传数据分析，以下是以功能医学疾病机制为科学依据对您关注的' +
        _vm.dataObj.singleAnalysisInModel.singleAnalysisListForSick[1]
          .healthTrouble.projectName +
        '健康主题进行分析的结果'}})]:_vm._e(),_c('Recommandations',{attrs:{"pageNum":7 + _vm.setNum1 + _vm.setNum2,"pageAllNum":_vm.pageAllNum,"objData":_vm.dataObj}}),_c('Recommandations2',{attrs:{"pageNum":8 + _vm.setNum1 + _vm.setNum2,"pageAllNum":_vm.pageAllNum,"objData":_vm.dataObj}}),_c('Recommandations3',{attrs:{"pageNum":12,"pageAllNum":_vm.pageAllNum,"objData":_vm.dataObj},on:{"toAllpageF":_vm.toAllpageF}})],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }