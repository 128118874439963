<template>
  <div class="pageSize home" style="overflow: hidden;">
    <div class="analysisReport_home_info1">
      <img src="https://image.giantgocloud.com/www/ImageMapping/image/20241119/96C0C7BD5EBD4C10BC47C8853268A49A.png"
        alt="">
      <div class="analysisReport_home_info1_title">
        全身健康功能分析
      </div>
      <div class="analysisReport_home_info1_en">Comprehensive Health</div>
    </div>
    <div class="analysisReport_home_info2">
      <div class="wh100" style='position: relative;'>
        <div class="analysisReport_home_info2_li" style="top: 487px;">
          <div class="wh100" style='position: relative;'>
            <div class="analysisReport_home_info2_li_left">姓名 / Compellation: </div>
            <div class="analysisReport_home_info2_li_right" v-if="userInfo?.userName">{{ userInfo.userName }}</div>
          </div>
        </div>
        <div class="analysisReport_home_info2_li" style="top: 557px;">
          <div class="wh100" style='position: relative;'>
            <div class="analysisReport_home_info2_li_left">性别 / Gender: </div>
            <div class="analysisReport_home_info2_li_right" v-if="userInfo?.userName">{{ userInfo.sex }}</div>
          </div>
        </div>
        <div class="analysisReport_home_info2_li" style="top: 626px;">
          <div class="wh100" style='position: relative;'>
            <div class="analysisReport_home_info2_li_left">年龄 / Age: </div>
            <div class="analysisReport_home_info2_li_right" v-if="userInfo?.userName">{{ userInfo.userAge }}</div>
          </div>
        </div>
        <div class="analysisReport_home_info2_li" style="top: 696px">
          <div class="wh100" style='position: relative;'>
            <div class="analysisReport_home_info2_li_left">出生日期 / Date Of Birth: </div>
            <div class="analysisReport_home_info2_li_right" v-if="userInfo?.userName">{{ userInfo.userBirthDay }}</div>
          </div>
        </div>
        <div class="analysisReport_home_info2_li" style="top:764px;">
          <div class="wh100" style='position: relative;'>
            <div class="analysisReport_home_info2_li_left">报告日期 / Report Date:</div>
            <div class="analysisReport_home_info2_li_right" v-if="userInfo?.userName">{{ userInfo.checkDate }}</div>
          </div>
        </div>
      </div>
    </div>
    <div style="position: absolute; top: 1460px; left: 292px;z-index: 99999;">
      <div style="margin-left: 200px;">
        <img :src="userInfo.logoImageUrl"
          alt="">
      </div>
      <div style="margin-left: 40px;">
        <img src="https://image.giantgocloud.com/www/ImageMapping/image/20241119/5FFF92995CE84EB698637D55B6B38AB5.png"
          alt="">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['userInfo', 'sickName', 'logoPicture'],
  data() {
    return {

    }
  }
}
</script>
<style lang="scss">
.home {
  background-size: 100% 100%;
  position: relative;
  color: #FFFFFF;
  background-color: #fff;

  .header_logo {
    width: 224px;
    height: 144px;
    // background-color: #75D2EC;
    margin: 0 auto;
    margin-top: 102px;
  }

  .header_title {
    width: 780px;
    height: 154px;
    margin: 0 auto;
    margin-top: 40px;
  }

  .header_sick {
    font-family: Source Han Sans CN;
    font-weight: 600;
    font-size: 36px;
    color: #474F57;
    text-align: center;
    padding-top: 40px;
  }

  .analysisReport_home_info1 {
    position: relative;
    margin-top: 80px;
    .analysisReport_home_info1_title {
      position: absolute;
      z-index: 9999;
      left: 287px;
      top: 744px;
      width: 569px;
      height: 77px;
      line-height: 77px;
      background: #fff;
      border-radius: 38px;
      font-size: 48px;
      color: #33AEAA;
      text-align: center;
      font-weight: bold;
    }
    .analysisReport_home_info1_en{
      position: absolute;
      z-index: 9999;
      left: 320px;
      top: 820px;
      font-size: 48px;
      color: #fff;
    }
  }

  .analysisReport_home_info2 {
    position: absolute;
    width: 992px;
    height: 940px;
    top: 580px;
    left: 92px;

    .analysisReport_home_info2_li {
      display: flex;
      align-items: center;
      position: absolute;
      height: 56px;
      width: 100%;
      background-color: #EFEFEF;
      color: #898989;
      font-size: 24px;

      .analysisReport_home_info2_li_left {
        position: absolute;
        right: 488px;
        top: 12px;
      }

      .analysisReport_home_info2_li_right {
        position: absolute;
        left: 543px;
        top: 12px;
      }
    }
  }
}

.home_title_JZ {
  position: absolute;
  bottom: 120px;
  left: 318px;
  width: 549px;
  height: 75px;
  background-color: #fff;
  z-index: 1;
  border-radius: 40px;
  font-size: 36px;
  font-weight: 700;
  color: #08a09a;
}
</style>
