<template>
  <div class="pageSize" v-if="objData">
    <Head text="健康管理方案" bgColor="#5aaca9"></Head>
    <div class="analysisReport_box11">
      <div class="analysisReport_box11_title">
        <div class="analysisReport_box11_title_text">
          个性化精准健康管理建议
        </div>
      </div>
      <div class="des_classs">
        个性化精准健康管理建议是通过AI精准分析你的生理和衰老指标数据，以功能医学理论为核心，遵循疾病发生机制，制定的一个效果可评估的个性化精准循证健康解决方案，旨在帮助你解决关键健康领域或逆转衰老过程，真正实现身材，皮肤，睡眠，精力和生理指标五大维度改变，做到由内而外的年轻化，重塑一个本来健康的你。
      </div>
    </div>
    <div class="manger" v-for="(item, index) in productList" :key="index">
      <div class="manger_title">{{ item.sickTitle }}</div>
      <div
        class="mager_item"
        v-for="(item1, index1) in item.productList"
        :key="index1"
      >
        <div class="marger_item_left">{{ item1.title }}</div>
        <div class="marger_item_right">
          <div
            class="item_list"
            v-for="(item2, index2) in item1.product"
            :key="index2"
          >
            {{ item2 }}
          </div>
        </div>
      </div>
    </div>
    <div
      class="analysisReportPage"
      style="
        position: absolute;
        right: 60px;
        bottom: 60px;
        font-size: 20px;
        color: #000;
      "
    >
      {{ pageNum }} of {{ pageAllNum }}
    </div>
  </div>
</template>
<script>
import Head from "./head.vue";
export default {
  props: ["objData", "pageNum", "pageAllNum"],
  data() {
    return {
      productList: [],
    };
  },
  methods: {},
  watch: {
    objData: {
      immediate: true,
      deep: true, // 开启深度监听 专门对付复杂数据类型
      handler: function (newValueA, oldValue) {
        this.productList = newValueA.singleAnalysisInModel.productManagerList;
        console.log(
          "newValueA6666",
          newValueA.singleAnalysisInModel.productManagerList
        );
      },
    },
  },
  components: {
    Head,
  },
};
</script>

<style lang="scss" scoped>
.des_classs {
  padding-top:20px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 20px;
  color: #474f57;
  line-height: 36px;
}
.analysisReport_box11 {
  width: 974px;
  margin: 0 auto;

  .domzdwPageDiv {
    margin-top: 0 !important;
  }

  .title {
    display: flex;
    font-size: 28px;
    color: #474f57;
  }

  .text {
    font-family: Source Han Sans CN;
    font-weight: 400;
    font-size: 20px;
    color: #474f57;
    padding-right: 118px;
    line-height: 36px;
  }

  .analysisReport_box11_title {
    display: flex;
    align-items: center;
    margin-top: 40px;

    .analysisReport_box11_title_num {
      font-family: Bahnschrift;
      font-weight: 400;
      font-size: 114px;
      color: #dfe3e8;
    }

    .analysisReport_box11_title_text {
      position: relative;
      top: -15px;
      left: -30px;

      margin-left: 15px;
      font-weight: 500;
      font-size: 30px;
      color: #5aaca9;

      &::after {
        content: "";
        position: absolute;
        bottom: -24px;
        left: 0px;
        width: 77px;
        height: 2px;
        background: #5aaca9;
        z-index: 1;
      }
    }
  }
}
.manger {
  width: 1012px;
  min-height: 200px;
  border: 1px solid #efefef;
  margin: 0 auto;
  margin-top: 30px;
  border-radius: 15px;
  padding-bottom: 30px;
  overflow: hidden;
  .manger_title {
    width: 100%;
    height: 56px;
    color: #33aeaa;
    font-size: 28px;
    background: #efefef;
    line-height: 56px;
    padding-left: 20px;
  }
  .mager_item {
    display: flex;
    padding-left: 30px;
    .marger_item_left {
      width: 224px;
      height: 38px;
      background: #33aeaa;
      line-height: 38px;
      border-radius: 19px;
      text-align: center;
      font-size: 23px;
      font-weight: 400;
      color: #ffffff;
      margin-right: 20px;
      margin-top: 30px;
    }
    .marger_item_right {
      display: flex;
      flex-wrap: wrap;
      padding-top: 20px;
      .item_list {
        padding: 0 10px;
        height: 40px;
        border-radius: 14px;
        border: 1px solid #33aeaa;
        font-family: Source Han Sans CN;
        font-weight: 400;
        font-size: 23px;
        color: #474f57;
        line-height: 40px;
        text-align: center;
        margin-right: 10px;
        margin-top: 10px;
      }
    }
  }
}
</style>
