<template>
  <div class="pageSize" v-if="objData" style="position: relative">
    <Head text="健康管理方案" bgColor="#5aaca9"></Head>
    <div class="analysisReport_box11">
      <div class="title" style="padding-top: 40px">健康管理方案建议</div>
      <div class="food">
        <div
          class="food_num"
          v-for="(item1, index1) in productList"
          :key="index1"
        >
          <div class="productTitle">{{ item1.showTitle }}</div>
          <div class="productContent">
            <div class="productContent_left">
              <div class="productContent_left_name">
                产品名称：{{ item1.showProductName }}
              </div>
              <div class="productContent_line"></div>
              <div
                class="productContent_left_effect"
                v-for="(item2, index2) in item1.effect"
                :key="index2"
              >
                <div class="effect_img">
                  <img
                    src="https://image.giantgocloud.com/www/ImageMapping/image/20240606/13D5196D44294522A8D00F420E2FE084.png"
                    alt=""
                    style="width: 100%; height: 100%"
                  />
                </div>
                <div class="effect_text">
                  {{ item2.effect }}<span v-if="item2.ingredient">:</span
                  >{{ item2.ingredient }}
                </div>
              </div>
            </div>
            <div class="productContent_right">
              <img :src="item1.productPicture" alt="" class="img" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="analysisReportPage" style="position: absolute;right: 60px;bottom: 60px;font-size: 20px;color: #000">
      {{ pageNum }} of {{ pageAllNum }}
    </div>
  </div>
</template>

<script>
import Head from "./head.vue";
export default {
  props: ["objData", "stageHealthyManageList", "pageNum", "pageAllNum"],
  data() {
    return {
      showData: false,
      productList: [],
      pageNumA: 0,
      pagesListNum: 1,
    };
  },
  methods: {
    chunked(arr) {
      return arr.reduce((accumulator, currentValue, currentIndex) => {
        const chunkIndex = Math.floor(currentIndex / 3);
        if (!accumulator[chunkIndex]) {
          accumulator[chunkIndex] = [];
        }
        accumulator[chunkIndex].push(currentValue);
        return accumulator;
      }, []);
    },
    removeDuplicatesByName(arr) {
      return arr.map((subArray) => {
        // 使用一个Set来存储已经遇到的name值
        const seenNames = new Set();
        // 使用filter来过滤掉重复的对象
        return subArray.filter((obj) => {
          if (!seenNames.has(obj.showProductName)) {
            seenNames.add(obj.showProductName);
            return true;
          }
          return false;
        });
      });
    },
  },
  watch: {
    objData: {
      immediate: true,
      deep: true, // 开启深度监听 专门对付复杂数据类型
      handler: function (newValueA, oldValue) {
        const masterProductList =
          newValueA.singleAnalysisInModel.productModel.selfInnovateProduct
            ?.masterProductList;
        const factorProductList =
          newValueA.singleAnalysisInModel.productModel.selfInnovateProduct
            ?.factorProductList;
        const otherProductList =
          newValueA.singleAnalysisInModel.productModel.selfInnovateProduct
            ?.otherProductList;
        const masterProductListData = masterProductList || [];
        const factorProductListData = factorProductList || [];
        const otherProductListData = otherProductList || [];
        const productList = [
          ...masterProductListData,
          ...factorProductListData,
          ...otherProductListData,
        ];
        if (productList.length == 0) {
          this.showData = false;
        } else {
          this.showData = true;
        }
        const productList1 = [];
        // eslint-disable-next-line no-unused-expressions
        productList?.map((v, i) => {
          v.groupProductList.map((v1, i1) => {
            if (i1 == 0) {
              productList1.push({
                showTitle: v.showTitle,
                showSickName: v.showSickName,
                productPicture: v1.productPicture,
                effect: v1.effect,
                showProductName: v1.showProductName,
                unBalanceName: v1.unBalanceName,
                productId: v1.productId,
                showSickName: v1.showSickName,
              });
            } else {
              productList1.push({
                showTitle: null,
                showSickName: null,
                productPicture: v1.productPicture,
                effect: v1.effect,
                showProductName: v1.showProductName,
                productId: v1.productId,
              });
            }
          });
        });
        const productListRepir = this.chunked(productList1).flat();
        console.log("productListRepir", productListRepir);
        this.productList = productListRepir.reduce((acc, item) => {
          if (!acc[item.productId]) {
            acc[item.productId] = item;
          }
          return acc;
        }, {});
      },
    },
  },
  components: {
    Head,
  },
};
</script>

<style lang="scss" scoped>
.analysisReport_box11 {
  width: 974px;
  margin: 0 auto;

  .domzdwPageDiv {
    margin-top: 0 !important;
  }

  .title {
    display: flex;
    font-size: 28px;
    color: #474f57;
    padding-top: 20px;
  }

  .text {
    font-family: Source Han Sans CN;
    font-weight: 400;
    font-size: 20px;
    color: #474f57;
    padding-right: 118px;
    line-height: 36px;
  }
  .analysisReport_box11_title {
    display: flex;
    align-items: center;
    margin-top: 65px;

    .analysisReport_box11_title_num {
      font-family: Bahnschrift;
      font-weight: 400;
      font-size: 114px;
      color: #dfe3e8;
    }

    .analysisReport_box11_title_text {
      position: relative;
      top: -15px;
      margin-left: 15px;
      font-weight: 500;
      font-size: 30px;
      color: #5aaca9;

      &::after {
        content: "";
        position: absolute;
        bottom: -24px;
        left: 0px;
        width: 77px;
        height: 2px;
        background: #5aaca9;
        z-index: 1;
      }
    }
  }

  .analysisReport_box11_content {
    margin-top: 20px;

    .analysisReport_box11_content_title {
      display: inline-flex;
      justify-content: space-between;
      align-items: center;
      padding: 13px 30px 14px 35px;
      border-radius: 0 40px 40px 0;
      background-color: #e0f4f3;
      font-weight: 500;
      font-size: 36px;
      color: #5aaca9;
    }

    .analysisReport_box11_content_img {
      margin-top: 43px;
      width: 100%;
      height: 250px;
    }

    .analysisReport_box11_content_ul {
      margin-top: 66px;

      .analysisReport_box11_content_li {
        margin-bottom: 15px;

        .analysisReport_box11_content_li_title {
          position: relative;
          font-size: 28px;
          color: #5aaca9;

          &::after {
            content: "";
            position: absolute;
            bottom: -10px;
            left: 0px;
            width: 77px;
            height: 3px;
            background: #5aaca9;
            z-index: 1;
          }
        }

        .analysisReport_box11_content_li_text {
          margin-top: 15px;
          font-size: 20px;
        }
      }
    }
  }
}

.analysisReport_box11_S6_ul {
  margin-top: 20px;

  .analysisReport_box11_S6_li {
    display: flex;
    align-items: center;
    margin-top: 10px;
    font-size: 20px;

    .analysisReport_box11_S6_li_A {
      display: flex;
      align-items: center;
      width: 200px;
      height: 50px;
      margin-right: 10px;
    }

    .analysisReport_box11_S6_li_B {
      display: flex;
      align-items: center;
      width: 280px;
      height: 39px;
      border-radius: 15px;
      border: 1px solid red;
      margin-right: 10px;

      .analysisReport_box11_S6_li_B_d {
        margin: 0 13px 0 13px;
        width: 7px;
        height: 7px;
        background: #0d1720;
        border-radius: 50%;
      }
    }
  }
}

.productTitle {
  padding: 10px 0;
  padding-left: 104px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  font-size: 32px;
  color: #2baea9;
}

.productContent {
  display: flex;
  justify-content: space-between;

  .productContent_left {
    font-family: Source Han Sans CN;
    font-weight: 400;
    font-size: 18px;
    color: #474f57;

    .productContent_left_name {
      font-family: Source Han Sans CN;
      font-weight: 400;
      font-size: 22px;
      color: #474f57;
    }

    .productContent_line {
      margin: 10px 0;
      margin-bottom: 20px;
      width: 600px;
      height: 0.5px;
      border: 0.5px solid #2baea9;
      opacity: 0.1;
    }

    .productContent_left_effect {
      display: flex;
      align-items: center;
      padding: 2px 0;

      .effect_img {
        width: 18px;
        height: 18px;
      }

      .effect_text {
        padding-left: 10px;
        font-size: 13px;
        color: #474f57;
      }
    }
  }

  .productContent_right {
    overflow: hidden;
    width: 140px;
    height: 140px;
    background: #ffffff;
    border-radius: 27px;
    border: 4px solid #c6e5df;
    margin-right: 87px;

    .img {
      width: 100%;
      height: 100%;
    }
  }
}

.analysisReport_box11 {
  width: 974px;
  margin: 0 auto;

  .analysisReport_box11_title {
    display: flex;
    align-items: center;
    margin-top: 65px;

    .analysisReport_box11_title_num {
      font-family: Bahnschrift;
      font-weight: 400;
      font-size: 114px;
      color: #dfe3e8;
    }

    .analysisReport_box11_title_text {
      position: relative;
      top: -15px;
      margin-left: 15px;
      font-weight: 500;
      font-size: 42px;
      color: #5aaca9;

      &::after {
        content: "";
        position: absolute;
        bottom: -24px;
        left: 0px;
        width: 77px;
        height: 2px;
        background: #5aaca9;
        z-index: 1;
      }
    }
  }

  .analysisReport_box11_content {
    margin-top: 20px;

    .analysisReport_box11_content_title {
      display: inline-flex;
      justify-content: space-between;
      align-items: center;
      padding: 3px 20px 3px 25px;
      border-radius: 0 40px 40px 0;
      background-color: #e0f4f3;
      font-weight: 500;
      font-size: 36px;
      color: #5aaca9;
    }

    .analysisReport_box11_content_img {
      margin-top: 43px;
      width: 100%;
      height: 250px;
    }

    .analysisReport_box11_content_ul {
      margin-top: 26px;

      .analysisReport_box11_content_li {
        margin-bottom: 15px;

        .analysisReport_box11_content_li_title {
          position: relative;
          font-size: 18px;
          color: #5aaca9;

          &::after {
            content: "";
            position: absolute;
            bottom: -10px;
            left: 0px;
            width: 77px;
            height: 3px;
            background: #5aaca9;
            z-index: 1;
          }
        }

        .analysisReport_box11_content_li_text {
          margin-top: 15px;
          font-size: 16px;
          color: #474f57;
        }
      }
    }
  }
}

.analysisReport_box11_S6_ul {
  margin-top: 20px;

  .analysisReport_box11_S6_li {
    display: flex;
    align-items: center;
    margin-top: 10px;
    font-size: 20px;

    .analysisReport_box11_S6_li_A {
      display: flex;
      align-items: center;
      width: 200px;
      height: 50px;
      margin-right: 10px;
    }

    .analysisReport_box11_S6_li_B {
      display: flex;
      align-items: center;
      width: 230px;
      height: 59px;
      border-radius: 15px;
      border: 1px solid red;
      margin-right: 10px;

      .analysisReport_box11_S6_li_B_d {
        margin: 0 13px 0 23px;
        width: 7px;
        height: 7px;
        background: #0d1720;
        border-radius: 50%;
      }
    }
  }
}

.food {
  padding-top: 10px;

  .food_num {
    font-size: 24px;
    color: #33aeaa;
    font-weight: 600;
  }

  .food_content {
    width: 950px;
    min-height: 40px;
    border: 1px solid #efefef;
    margin-top: 20px;
    border-radius: 6px;
    padding-bottom: 20px;

    .food_title {
      display: flex;
      align-items: center;
      width: 100%;
      height: 40px;
      background-color: #efefef;
      padding-left: 20px;
      font-size: 18px;
      color: #33aeaa;
    }

    .food_list {
      display: flex;
      justify-content: space-between;

      .food_left {
        padding-left: 20px;
        padding-top: 10px;

        .food_left_item {
          display: flex;
          align-items: center;
          line-height: 22px;

          .dot {
            width: 6px;
            height: 6px;
            border: 1px solid #33aeaa;
            border-radius: 3px;
          }

          .text_list {
            color: #474f57;
            font-size: 14px;
            padding-left: 5px;
          }
        }
      }

      .food_right {
        width: 140px;
        height: 140px;
        margin-top: 10px;
        margin-right: 20px;
        border-radius: 10px;
        overflow: hidden;
      }
    }
  }
}
</style>
