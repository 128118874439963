<template>
    <div  class="pageSize main" :style="'background: url('+imgUrl+') no-repeat;background-size: 100% 100%;'">
      <!-- <Head text="健康分析" bgColor="#2baea9"></Head> -->
        <!-- <div class="title_borderground">
            <div class="title_borderground_content1">{{ text }}</div>
            <div class="title_borderground_content2">
                专业健康知识</div>
        </div> -->
        <div class="footerNum">1 of 6</div>
    </div>
</template>

<script>
import Head from './head.vue'
export default {
  props: ['imgUrl'],
  data () {
    return {
      text: ''
    }
  },
  components: {
    Head
    // Bottom
  }
  // props: ['objData'],
  // watch: {
  //   objData: {
  //     immediate: true,
  //     deep: true, // 开启深度监听 专门对付复杂数据类型
  //     handler: function (newValueA, oldValue) {
  //       // console.log('objData', newValueA)
  //       const isPdf = newValueA.isAccurateOrFunction
  //       if (isPdf == 1) {
  //         this.text = newValueA.functionAnalysisList[0].sickName
  //       } else {
  //         this.text = newValueA.singleAnalysisInModel.singleAnalysisListForSick.map(v => v.healthTrouble.projectName).join('&')
  //       }
  //     }
  //   }
  // }
}
</script>

<style lang="scss" scoped>

.main {
    // background: url('https://image.giantgocloud.com/www/ImageMapping/image/20241112/1AA23202FAE546659E743A769D97F543.jpg') no-repeat;
    background-size: 100% 100%;
    position: relative;
    overflow: hidden;
    z-index: 999;

    .title_borderground {
        position: absolute;
        bottom: 589px;
        left: 152px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        font-size: 48px;
        color: #2bafaa;
    }

    .title_borderground_content1 {
        padding-left: 30px;
    }

    .title_borderground_content2 {
        padding-left: 140px;
    }
    .footerNum{
        z-index: 9999999999;
        position: absolute;
        bottom: 60px;
        left: 92%;
        font-family: Source Han Sans CN;
        font-weight: 400;
        font-size: 20px;
        color: #000;
    }
}
</style>
