<template>
  <div class="heart">
    <div class="heart_title">
      <div class="round"></div>
      <div class="info">{{ titleInfo.organName }}</div>
    </div>
    <div class="risk">
      <div class="risk_left">
        <div class="risk_left_lsit">
          <div class="risk_left_number">
            <div :style="{ color: titleInfo.colorCode }">
              {{ titleInfo.colorLevel }}
            </div>
          </div>
          <div class="risk_left_time">截止时间{{ dateTime }}</div>
        </div>
        <div class="risk_left_lsit" v-if="showResult.textValue">
          <div class="risk_left_number">
            <div :style="{ color: showResult.color }">
              {{ showResult.textValue }}
            </div>
          </div>
          <div class="risk_left_time">上次结果{{ showResult.date }}</div>
        </div>
      </div>
      <div class="risk_right">
        <div class="risk_ritht_speed">
          <div class="speed_list">
            <div class="speed_list_item" v-for="(item, index) in gradeList">
              {{ item }}
            </div>
          </div>
          <div class="speed_line">
            <div
              class="speed_line_item"
              v-for="(item, index) in gradeColor"
              :style="{ background: item }"
            >
              <div class="triangle" v-if="paddingLeft == index">
                <img
                  class="wh100"
                  src="https://image.giantgocloud.com/www/ImageMapping/image/20240704/D0561726353F43FA8CD30A25707792CA.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <div
          ref="riskRithtChat"
          style="width: 38vmax; height: 28vmax; margin-top: 2vmax"
        ></div>
      </div>
    </div>
    <template v-if="calculateDescribe">
      <div class="heart_title">
        <div class="round"></div>
        <div class="info">评分健康等级介绍</div>
      </div>
      <div class="calculateDescribe">
        {{ calculateDescribe }}
      </div>
    </template>
    <template v-if="organIndicators">
      <div
        class="heart_title"
        style="padding-top: 2vmax; padding-bottom: 1vmax"
      >
        <div class="round"></div>
        <div class="info">相关指标</div>
        <div style="padding-left: 0.6vmax; font-size: 1vmax">
          (带<span style="color: red">☆</span>为重要评估指标)
        </div>
      </div>
      <div class="organIndicators" v-for="(item, index) in organIndicators">
        <template v-if="item.isKeyNote == 1">
          <div class="organIndicatorsName" v-if="item.indicatorsName">
            {{ item.indicatorsName }}
          </div>
          <div class="organIndicatorsName" v-else>{{ item.indicatorName }}</div>
          <div class="organIndicatorsIcon">
            <img
              src="https://image.giantgocloud.com/www/ImageMapping/image/20240924/2443527E0EA44153B81B759E0DB30E48.png"
              class="wh100"
              alt=""
            />
          </div>
        </template>
      </div>
      <div class="organIndicators" v-for="(item, index) in organIndicators">
        <template v-if="item.isKeyNote != 1">
          <div class="organIndicatorsName" v-if="item.indicatorsName">
            {{ item.indicatorsName }}
          </div>
          <div class="organIndicatorsName" v-else>{{ item.indicatorName }}</div>
          <!-- <div class="organIndicatorsIcon">
            <img
              src="https://image.giantgocloud.com/www/ImageMapping/image/20240924/2443527E0EA44153B81B759E0DB30E48.png"
              class="wh100" alt="">
          </div> -->
        </template>
      </div>
    </template>
    <!-- <template v-if="selectionParames.indicatorsDescription">
      <div class="heart_title">
        <div class="round"></div>
        <div class="info">指标描述</div>
      </div>
      <div class="indicatorsDescription">
        {{ selectionParames.indicatorsDescription }}
      </div>
    </template> -->
    <!-- <template v-if="selectionParames.type != 3">
      <div class="line_excessive"></div>
      <div class="heart_tabs">
        <div class="heart_tabs_item" :class="currentIndex == index + 1 ? 'tab_active' : ''"
          @click="currentIndex = index + 1" v-for="(item, index) in tabList" :key="index">
          {{ item.name }}
        </div>
      </div>
      <template v-if="currentIndex == 1">
        <div class="explain">
          有{{ dataInfo.indicatorModels?.length }}个{{ titleInfo
          }}风险因素，血液生物标志构成根据各自对该类别的影响进行加权，由我们的专家和关于该标志物的现有证据确定。
        </div>
        <div class="standard">
          <div class="standard_item" v-for="(item, index) in dataInfo.indicatorModels" :key="index">
            <div>
              <div class="standard_item_top" :style="{ color: item.colorCode }">{{ item.indicatorName }}
              </div>
              <div class="standard_item_down"> {{ item.indicatorValue }}/{{ item.indicatorUnit }}</div>
            </div>
          </div>
        </div>
        <div class="round a_flex" style="margin-top: 2.3vmax;">
          <PieZ widthA='25' :dataList="dataInfo.allIndicator" />
        </div>
        <div class="heart_title">
          <div class="round"></div>
          <div class="info">您的{{ titleInfo }}健康如何分析的</div>
        </div>
        <div class="analysis_content">
          共有{{ dataInfo.allIndicator?.length
          }}个生物标志物构成了你的{{ titleInfo
          }}健康评分。血液生物标志物根据其各自对该类别的影响进行加权，由我们的专家和关于该标志物的现有证据确定。生理析志物的贡献将很快出现在该平台上。随着对这些生物标志物的研究的发展，您在平台上看到的分数也会发展。您为最新结果集上每个标记降落的区域决定了其对总分的贡献。标志物的研究的发展，您在平台上看到的分数也会发展。您为最新结果集上每个标记降落的区域决定了其对总分的贡献。
        </div>
        <div class="analysis_count">
          *指标参照
        </div>
        <div class="analysis_lsit">
          <div class="analysis_lsit_item" v-for="(item, index) in dataInfo.allIndicator" :key="index">
            <span style="flex: 1;"> {{ item.indicatorEnName }}</span><span style="color: #20D0D9 ;">-</span>
            <span style="flex: 1">
              {{ item.indicatorName }}
            </span>
          </div>
        </div>
        <div class="analysis_warn">
          注意-本平台上提供的计算仅用于教育目的。每个计算都根据当前的科学证据和您最新的生物标志物数据来 估计标记对健康的影响
        </div>
      </template>
      <template v-if="currentIndex == 2">
        222{{ currentOrgin }}
      </template>
      <template v-if="currentIndex == 3">
        333
      </template>
      <template v-if="currentIndex == 4">
        444
      </template>
      <template v-if="currentIndex == 5">
        555
      </template>
    </template> -->
  </div>
</template>
<script>
import PieZ from "/src/views/userInformation/components/pieZ.vue";
import * as echarts from "echarts";
import { GetOrganDetail, GetSickDetail, GetIndicatorDetail } from "@/api/index";
export default {
  components: {
    PieZ,
  },
  props: ["selectionParames"],
  data() {
    return {
      paddingLeft: "15px", // 15px 100px  185px  270px
      gradeList: ["严重", "疾病", "风险", "正常", "最佳"].reverse(),
      gradeColor: ["#10854A", "#5EC692", "#F9C716", "#fe7283", "#D51931"],
      organColorCode: "",
      dateTime: "",
      currentIndex: 1,
      calculateDescribe: "",
      organIndicators: null,
      tabList: [
        {
          id: 1,
          name: "健康分析",
        },
        // {
        //     id: 2,
        //     name: '生活方式建议'
        // },
        // {
        //     id: 3,
        //     name: '饮食建议'
        // },
        // {
        //     id: 4,
        //     name: '营养建议'
        // },
        // {
        //     id: 5,
        //     name: '健康学习'
        // }
      ],
      dataInfo: "",
      titleInfo: "",
      myChart: null,
      showResult: {
        textValue: null,
        color: null,
        date: "",
      },
    };
  },
  mounted() {
    // this.drawChart()
  },
  watch: {
    selectionParames: {
      immediate: true,
      deep: true, // 开启深度监听 专门对付复杂数据类型
      handler: function (newValueA, oldValue) {
        if (newValueA) {
          this.drawChart(newValueA);
          this.titleInfo = newValueA;
        }
      },
    },
  },
  methods: {
    async drawChart(newValueA) {
      console.log(newValueA, "绘制图表2222");
      // return
      // 2. 基于准备好的dom，初始化echarts实例
      // 此处的意思就是，对 demo 元素 进行图表初始化的相关操作
      // myChart = this.$echarts.init(document.getElementById('riskRithtChat'))
      this.$nextTick(async () => {
        this.myChart = echarts.init(this.$refs.riskRithtChat);
        if (this.myChart) {
          const params = {
            customerCode: this.$query("params"),
            ...newValueA,
          };
          this.calculateDescribe = newValueA.calculateDescribe;
          this.organIndicators = newValueA.organIndicators;
          let sourceData = [];
          if (newValueA.type == 1) {
            this.$emit("closeLoading", { isShow: true });
            const { data } = await GetOrganDetail(params);
            sourceData = data;
            if (data) {
              this.$emit("closeLoading", { isShow: false });
            }
          } else {
            const { data } = await GetIndicatorDetail(params);
            sourceData = data;
          }
          if (sourceData.trendDatas && sourceData.trendDatas.length > 1) {
            this.showResult.color = sourceData.trendDatas[0].colorCode;
            this.showResult.date = sourceData.trendDatas[0].dateMonth;
            console.log("showResult8888", this.showResult);
            if (sourceData.trendDatas[0].colorLevel == 0.5) {
              this.showResult.textValue = "A+";
            } else if (sourceData.trendDatas[0].colorLevel == 0.75) {
              this.showResult.textValue = "A";
            } else if (sourceData.trendDatas[0].colorLevel == 1) {
              this.showResult.textValue = "A-";
            } else if (sourceData.trendDatas[0].colorLevel == 1.5) {
              this.showResult.textValue = "B+";
            } else if (sourceData.trendDatas[0].colorLevel == 1.75) {
              this.showResult.textValue = "B";
            } else if (sourceData.trendDatas[0].colorLevel == 2) {
              this.showResult.textValue = "B-";
            } else if (sourceData.trendDatas[0].colorLevel == 2.5) {
              this.showResult.textValue = "C+";
            } else if (sourceData.trendDatas[0].colorLevel == 2.75) {
              this.showResult.textValue = "C";
            } else if (sourceData.trendDatas[0].colorLevel == 3) {
              this.showResult.textValue = "C-";
            } else if (sourceData.trendDatas[0].colorLevel == 3.5) {
              this.showResult.textValue = "D+";
            } else if (sourceData.trendDatas[0].colorLevel == 3.75) {
              this.showResult.textValue = "D";
            } else if (sourceData.trendDatas[0].colorLevel == 4) {
              this.showResult.textValue = "D-";
            } else if (sourceData.trendDatas[0].colorLevel == 5) {
              this.showResult.textValue = "F";
            }
          } else {
            this.showResult.textValue = null;
          }
          console.log(sourceData, "器官详情数sss据");
          this.organColorCode = sourceData.organColorCode;
          if (sourceData.trendDatas && sourceData.trendDatas.length > 0) {
            this.dateTime =
              sourceData.trendDatas[sourceData.trendDatas.length - 1].dateMonth;
          }
          if (newValueA.colorLevel.includes("F")) {
            this.paddingLeft = 4;
          } else if (newValueA.colorLevel.includes("D")) {
            this.paddingLeft = 3;
          } else if (newValueA.colorLevel.includes("C")) {
            this.paddingLeft = 2;
          } else if (newValueA.colorLevel.includes("B")) {
            this.paddingLeft = 1;
          } else {
            this.paddingLeft = 0;
          }
          this.dataInfo = sourceData;
          const xAxisData = [];
          const seriesData = [];
          if (sourceData.trendDatas && sourceData.trendDatas.length > 0) {
            sourceData.trendDatas.map((v) => {
              xAxisData.push(v.dateMonth);
              seriesData.push(Number(v.colorLevel));
            });
          }
          console.log("xAxisData", xAxisData);
          console.log("seriesData", seriesData);
          console.log(sourceData, "器官详情数据");
          // 3. 指定图表的配置项和数据
          // 该处就是图表内容，在官网的示例里面，要复制过来到项目里面的也是这一块内容
          var option = {
            title: {
              text: "",
            },
            grid: {
              left: "4%",
              right: "3%",
              bottom: "10%",
              top: "3%",
              containLabel: true,
            },
            tooltip: {},
            legend: {
              // 图例组件
              show: false, // 设置不显示图例
            },
            xAxis: {
              data: xAxisData,
              axisTick: {
                show: false, // 是否显示刻度线
              },
            },
            yAxis: {
              type: "value",
              min: 0, // 设置y轴最小值
              max: 5, // 设置y轴最大值
              splitNumber: 11, // 设置y轴刻度数量，这里设置为11以确保包括0和10
              axisLabel: {
                show: true, // 是否显示刻度标签
                formatter: function (value) {
                  // 如果value是某个特定的值，就返回对应的中文描述
                  if (value == 0.5) {
                    return "A+";
                  } else if (value == 0.75) {
                    return "A";
                  } else if (value == 1) {
                    return "A -";
                  } else if (value == 1.5) {
                    return "B+";
                  } else if (value == 1.75) {
                    return "B";
                  } else if (value == 2) {
                    return "B -";
                  } else if (value == 2.5) {
                    return "C+";
                  } else if (value == 2.75) {
                    return "C";
                  } else if (value == 3) {
                    return "C -";
                  } else if (value == 3.5) {
                    return "D+";
                  } else if (value == 3.75) {
                    return "D";
                  } else if (value == 4) {
                    return "D -";
                  } else if (value == 5) {
                    return "F";
                  }
                },
                // textStyle: {
                //   color: '#ffffff99' // 设置标签文字的颜色
                // }
              },
              splitLine: {
                lineStyle: {
                  color: "rgba(159,174,208,0.1)",
                },
              },
            },
            series: [
              {
                type: "line",
                symbol: "circle", // 设置类型为 circle
                symbolSize: 8, // 圆点大小
                data: seriesData,
                silent: true, // 全局禁用点击事件
                // 设置线的颜色
                lineStyle: {
                  color: "#BFBFC4",
                  width: 1,
                  type: "solid",
                },
                // 设圆圈颜色
                itemStyle: {
                  color: (params) => {
                    const { color, data } = params;
                    if (data <= 1) {
                      return "#10854A";
                    }
                    if (data >= 1.5 && data <= 2) {
                      return "#5EC692";
                    }
                    if (data >= 2.5 && data <= 3) {
                      return "#F9C716";
                    }
                    if (data >= 3.5 && data <= 4) {
                      return "#fe7283";
                    }
                    if (data >= 4.5 && data <= 5) {
                      return "#D51931";
                    }
                    return color;
                  },
                },

                // itemStyle: {
                //     borderColor:'red'
                // }
                //   itemStyle: {
                //     color: '#262738',
                //     borderColor: 'red'
                //   }
              },
            ],
          };
          // 4.使用刚指定的配置项和数据显示图表。
          this.myChart.setOption(option);
          console.log("option", option);
        }
      }, 2);
    },
  },
};
</script>
<style lang="scss" scoped>
.heart {
  .heart_title {
    display: flex;
    align-items: center;

    .round {
      width: 0.8vmax;
      height: 0.8vmax;
      background: #18bfc8;
      border-radius: 50%;
    }

    .info {
      margin-left: 1vmax;
      padding: 0.2vmax 0.6vmax;
      background: #20d0d9;
      border-radius: 0.5vmax;
      font-family: PingFang SC, PingFang SC;
      font-weight: 600;
      font-size: 1.6vmax;
      color: #ffffff;
      text-align: left;
      font-style: normal;
      text-transform: none;
      padding-left: 0.6vmax;
    }
  }

  .calculateDescribe {
    font-size: 1.4vmax;
    padding-left: 1.5vmax;
    padding-top: 1vmax;
  }

  .organIndicators {
    display: flex;
    padding-left: 1.5vmax;

    .organIndicatorsName {
      font-size: 1.4vmax;
    }

    .organIndicatorsIcon {
      width: 1vmax;
      height: 1vmax;
      margin-top: 0.5vmax;
      margin-left: 0.2vmax;
    }
  }

  .indicatorsDescription {
    font-size: 1.2vmax;
    padding-left: 1.5vmax;
    padding-top: 0.6vmax;
    padding-right: 1.5vmax;
  }

  .risk {
    display: flex;

    .risk_left {
      padding-top: 1vmax;

      .risk_left_lsit {
        font-size: 1.2vmax;
        margin-top: 1.6vmax;

        .risk_left_number {
          display: flex;
          align-items: center;

          font-size: 1.2vmax;
          padding-left: 2vmax;

          .icon {
            margin-left: 0.4vmax;
            width: 0.6vmax;
            height: 0.8vmax;
          }
        }

        .risk_left_time {
          padding-top: 0.6vmax;
          font-size: 1.2vmax;
        }
      }
    }

    .risk_right {
      width: 100%;
      padding-top: 1vmax;

      .risk_ritht_speed {
        padding-left: 2vmax;

        .speed_list {
          display: flex;
          justify-content: space-around;
          font-size: 1.2vmax;
          width: 100%;

          .speed_list_item {
            padding-right: 2.8vmax;
          }
        }

        .speed_line {
          display: flex;
          width: 36vmax;
          margin-top: 0.6vmax;

          .speed_line_item {
            width: 7vmax;
            height: 0.2vmax;
            margin-left: 0.2vmax;
            .triangle {
              width: 0.8vmax;
              height: 0.8vmax;
              margin: 0 auto;
              margin-top: 0.6vmax;
            }
          }
        }
      }
    }
  }

  .line_excessive {
    width: 45vmax;
    height: 1px;
    margin-top: 2vmax;
    border-radius: 0px 0px 0px 0px;
    border: 1px solid;
    border-image: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 1),
        rgba(255, 255, 255, 0)
      )
      1 1;
  }

  .heart_tabs {
    display: flex;
    font-weight: 600;
    font-size: 1.2vmax;
    padding-top: 2.4vmax;

    .heart_tabs_item {
      height: 2.3vmax;
      padding: 0 1.6vmax;
      line-height: 2.3vmax;
    }

    .tab_active {
      background: linear-gradient(180deg, #20ced6 0%, #13989f 100%);
      border-radius: 403px 403px 403px 403px;
    }
  }

  .explain {
    font-size: 1.2vmax;
    font-weight: 400;
    opacity: 0.8;
    padding-top: 1.7vmax;
  }

  .standard {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 1.2vmax;

    .standard_item {
      display: flex;
      align-items: center;
      // justify-content: center;
      width: 14.4vmax;
      height: 4.8vmax;
      background: #38394d;
      border-radius: 1vmax;
      margin-right: 0.6vmax;
      margin-top: 0.6vmax;
      padding-left: 0.6vmax;

      .standard_item_top {
        font-size: 1.2vmax;
      }

      .standard_item_down {
        font-size: 1.2vmax;
      }
    }
  }

  .analysis_content {
    font-weight: 400;
    font-size: 1.2vmax;
    opacity: 0.8;
    padding-top: 1.5vmax;
  }

  .analysis_count {
    font-weight: 400;
    font-size: 1.2vmax;
    opacity: 0.8;
    padding-top: 1.5vmax;
  }

  .analysis_lsit {
    display: flex;
    flex-wrap: wrap;
    font-weight: 400;
    font-size: 1.2vmax;
    opacity: 0.8;

    .analysis_lsit_item {
      width: 14vmax;
      height: 4vmax;
      background: #38394d;
      border-radius: 1vmax;
      margin-left: 1vmax;
      margin-top: 1vmax;
      font-size: 1.2vmax;
      padding-top: 0.8vmax;
      padding-left: 0.6vmax;
      // text-align: center;
    }
  }

  .analysis_warn {
    font-weight: 400;
    font-size: 1.2vmax;
    opacity: 0.8;
    padding-top: 1.5vmax;
  }
}
</style>
