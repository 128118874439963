<template>
  <div class="info">
    <div class="info_content">
      <div class="tabs">
        <div class="tab_item" :class="currentIndex == index + 1 ? 'tab_active' : ''" @click="changeTab(index + 1)"
          v-for="(item, index) in tabList" :key="index">
          <div class="tab_item_icon" :style="'width:' + item.icW + 'vmax;height:' + item.icH + 'vmax;'">
            <img class="wh100" :src="currentIndex == index + 1 ? item.activeIcon : item.icon" alt="">
          </div>
          <div class="tab_item_name">
            {{ item.name }}
          </div>
          <div class="tab_item_line"></div>
        </div>
      </div>
      <div class="show_area">
        <template v-if="currentIndex == 1">
          <user />
        </template>
        <template v-if="currentIndex == 2">
          <achievement />
        </template>
        <template v-if="currentIndex == 3">
          <trendChart />
        </template>
        <template v-if="currentIndex == 4">
          <biologicalAge />
        </template>
        <template v-if="currentIndex == 5">
          <healthPlan />
        </template>
        <template v-if="currentIndex == 6">
          <lifeWay />
        </template>
      </div>
    </div>
    <!-- <div class="container" v-if="isShow == 1" :style="{ left: xPos + 'px', top: yPos + 'px' }" @touchstart="startDrag"
      @touchmove="onDrag" @touchend="endDrag" @touchcancel="endDrag">
      <div class="fanhui">
      返回
      </div>
    </div> -->
  <!--  <div>
      <Back />
    </div> -->
    <!-- <modelLoading :ModalBase="ModalBase"  v-if="isShowModel">
    </modelLoading> -->
  </div>
</template>
<script>
import user from './user/index.vue'
import achievement from './achievement/index.vue'
import trendChart from './trendChart/index.vue'
import biologicalAge from './biologicalAge/index.vue'
import healthPlan from './healthPlan/index.vue'
import lifeWay from './lifeWay/index.vue'
import Back from './components/back.vue'
import {
  GetSignatureByCustomerCode,
  GetCustomerApiList
} from '@/api/index'
import wx from 'weixin-js-sdk'
// import wx from "@util/jweixin1.3.2.js";
export default {
  components: {
    user,
    achievement,
    trendChart,
    biologicalAge,
    healthPlan,
    lifeWay,
    Back
    // modelLoading
  },
  data () {
    return {
      isShowModel: true,
      currentIndex: 1,
      isShow: 2,
      ModalBase: {
        boxHight: '18vmax',
        boxWidth: '28vmax',
        boxBgc: '#2B2C3D'
      },
      tabList: [
        {
          id: 1,
          name: '个人信息',
          icon: 'https://image.giantgocloud.com/www/ImageMapping/image/20240704/3EBDED6BA58243568BA8C9959F14F5A2.png',
          activeIcon: 'https://image.giantgocloud.com/www/ImageMapping/image/20240704/2713DF40D30E4F3490476AAE7C36EE7D.png',
          icW: '2.6',
          icH: '2.8'
        },
        {
          id: 2,
          name: '主题看板',
          icon: 'https://image.giantgocloud.com/www/ImageMapping/image/20240704/5178710211BB40E2A958AFB9CA80636F.png',
          activeIcon: 'https://image.giantgocloud.com/www/ImageMapping/image/20240704/01A87496CF2446F6B7A1A587AFCE2B20.png',
          icW: '2.3',
          icH: '2.8'
        },
        {
          id: 3,
          name: '标志物',
          icon: 'https://image.giantgocloud.com/www/ImageMapping/image/20240704/BC8A652EC34A41A292DD3AB44E827E0D.png',
          activeIcon: 'https://image.giantgocloud.com/www/ImageMapping/image/20240704/ABCC5A4AE0A04819A7138930B015FF1A.png',
          icW: '2.5',
          icH: '2.5'
        },
        {
          id: 4,
          name: '生理年龄',
          icon: 'https://image.giantgocloud.com/www/ImageMapping/image/20240704/CE468C8CD26E459E85871B2AE9C73721.png',
          activeIcon: 'https://image.giantgocloud.com/www/ImageMapping/image/20240704/632D945AC8DB414FA82C08172E2179CD.png',
          icW: '2.7',
          icH: '2.8'
        },
        {
          id: 5,
          name: '健康方案',
          icon: 'https://image.giantgocloud.com/www/ImageMapping/image/20240704/9FDEBF3DEBF64D2F973DDE25C6282E1B.png',
          activeIcon: 'https://image.giantgocloud.com/www/ImageMapping/image/20240822/F77CAB57CD1749FBAA186F95E321119A.png',
          icW: '2.7',
          icH: '2.2'
        }
        // {
        //   id: 6,
        //   name: '生活方式',
        //   icon: 'https://image.giantgocloud.com/www/ImageMapping/image/20240828/721AE5B5C85748EF8D8A73D7ED91503E.png',
        //   activeIcon: 'https://image.giantgocloud.com/www/ImageMapping/image/20240828/6077690F743545808F3C7A285DD8E2B0.png',
        //   icW: '3.4',
        //   icH: '2.8'
        // }
      ]
    }
  },
  props: {

  },
  created () {
    this.isShow = this.$query('token')
    this.GetCustomerApiList()
    console.log('88888', this.isShow)
  },
  mounted () {

  },
  methods: {
    changeTab (index) {
      const userType = this.$query('userType')
      if (index == 4 && userType == 2) {
        this.$toast.fail({
          message: '暂无权限！'
        })
        return
      }
      if (userType == 3 && index != 4 && index != 1) {
        this.$toast.fail({
          message: '暂无权限！'
        })
        return
      }
      if (userType == 4 && index == 4) {
        this.$toast.fail({
          message: '暂无权限！'
        })
        return
      }
      if (index == 6 && (userType == 3 || userType == 4)) {
        this.$toast.fail({
          message: '暂无权限！'
        })
        return
      }
      this.currentIndex = index
    },
    handleBack () {
      // 返回小程序
      // console.log('返回小程序')
      wx.miniProgram.redirectTo({
        url: '/view/userManagement/userProfile/index'
      })
    },
    getShareInfo () {
      const that = this
      var nonceStr = that.createNonceStr()
      const timeStamp = Date.parse(new Date()) / 1000
      const winUrl = window.location.href.split('#')[0]
      const winUrlParams = `${winUrl}userInformation?params=${this.$query('params')}`
      // console.log(winUrlParams, 'winUrlParams')
      const parames = {
        // url: encodeURIComponent(winUrl),
        url: winUrl,
        customerCode: this.$query('params'),
        timeStamp,
        nonceStr: nonceStr
      }
      GetSignatureByCustomerCode(parames).then(
        (msg) => {
          console.log(msg.data.appId)
          wx.config({
            debug: false, // 开启调试模式
            appId: msg.data.appId, // 必填，公众号的唯一标识
            timestamp: timeStamp, // 必填，生成签名的时间戳
            nonceStr: nonceStr, // 必填，生成签名的随机串
            signature: msg.data.signature, // 必填，签名，见附录1
            jsApiList: ['updateAppMessageShareData'] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
          })
          wx.ready(function () {
            // wx.showMenuItems({
            //   menuList: ['menuItem:share:appMessage'] // 要显示的菜单项，所有menu项见附录3
            // });
            // 分享到朋友圈  需在用户可能点击分享按钮前就先调用
            // wx.updateTimelineShareData({
            //   title: that.activeObj.ac_title, // 分享时的标题
            //   desc: that.activeObj.share_desc, // 分享描述
            //   link: that.activeObj.share_url, // 分享时的链接
            //   imgUrl: that.activeObj.share_image, // 分享图标
            //   success: function () {
            //     // 设置成功
            //     console.log("分享到朋友圈成功");
            //   },
            //   cancel: function () {
            //     // 取消设置
            //     console.log("分享到朋友圈取消");
            //   },
            //   fail: function () {
            //     console.log("分享朋友圈失败");
            //   },
            // });

            // wx.checkJsApi({
            //   jsApiList: ['updateAppMessageShareData'], // 需要检测的JS接口列表，所有JS接口列表见附录2,
            //   success: function (res) {
            //     // 以键值对的形式返回，可用的api值true，不可用为false
            //     // 如：{"checkResult":{"chooseImage":true},"errMsg":"checkJsApi:ok"}
            //   }
            // });
            // 分享给朋友的设置
            wx.updateAppMessageShareData({
              title: `${msg.data.userName}的健康数据看板`, // 分享时的标题
              desc: '', // 分享描述
              link: window.location.href, // 分享时的链接
              imgUrl: '', // 分享图标
              success: function () {
                // 设置成功
                console.log('分享给朋友成功')
              },
              cancel: function () {
                // 设置失败
                console.log('分享给朋友失败')
              },
              fail: function () {
                console.log('分享朋友圈失败')
              }
            })
          })
        }
      )
    },
    createNonceStr: function () {
      return Math.random().toString(36).substr(2, 15)
    },
    async GetCustomerApiList () {
      const { data } = await GetCustomerApiList({
        userCode: this.$query('params')
      })
      console.log(data, '权限接口列表')
    }
    // async handleShare() {
    //   try {
    //     const url = window.location.href// 需要复制的URL
    //     await navigator.clipboard.writeText(url);
    //     console.log("URL已复制到剪贴板");
    //     this.$toast('复制成功，请在微信中打开分享！');
    //   } catch (error) {
    //     console.error("复制URL到剪贴板时发生错误", error);
    //   }
    // }

  }
}
</script>
<style lang='scss' scoped>
* {
  padding: 0;
  margin: 0;
}

.container {
  position: fixed;
  /* 或者其他定位方式，取决于你的布局 */
  left: 10px;
  /* 初始位置 */
  top: 50px;
  /* 初始位置 */
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #007bff;
  color: white;
  border-radius: 50%;
  cursor: pointer;
  user-select: none;
  /* 触摸反馈效果，可选 */
  touch-action: none;

  // .fanhui {
  //   border-radius: 50%;
  //   width: 40px;
  //   height: 40px;
  //   text-align: center;
  //   line-height: 40px;
  //   background-color: #20D0D9;
  //   cursor: move;
  //   user-select: none;
  // }

}

.info {
  box-sizing: border-box;
  width: 100vw;
  min-height: 100vh;
  background: radial-gradient(#26284a, #22232f, #0e0e14, #000);
  background-position-x: center;
  background-position-y: center;
  background-size: 100% 100%;
  color: #FFFFFF;
  padding-top: 1vmax;

  .info_content {
    display: flex;

    .tabs {
      padding-top: 0.5vmax;
      box-sizing: border-box;
      width: 7vmax;
      height: 95.1vmin;
      background: linear-gradient(91deg, #38394D 0%, #2B2C3C 100%);
      border-radius: 1vmax;
      margin-left: 1vmax;
      text-align: center;
      font-size: 16px;

      .tab_item {
        padding-top: 1.5vmax;

        .tab_item_icon {
          width: 2.6vmax;
          height: 2.8vmax;
          margin: 0 auto;
        }

        .tab_item_name {
          font-size: 1.6vmax;
          padding-top: 1vmax;
        }

        .tab_item_line {
          width: 6.6vmax;
          height: 0.1px;
          margin-top: 2vmax;
          border: 0.1vmax solid;
          border-image: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 1 1;
        }
      }

      .tab_active {
        color: #20D0D9 !important;
      }
    }

    .show_area {}
  }
}
</style>
