<template>
  <div class="testReport_pages13 pageSize">

    <Head text="健康管理方案" bgColorRight="#5aaca9" bgColor="#5aaca9"></Head>
    <div class="testReport_pages4_title_box">
      <div class="title">{{sickNameText }}发病机制</div>
      <div class="line"></div>
    </div>
    <div class="testReport_text">
      {{ showData.sickIntroduce }}
    </div>
    <div class="testReport_img">
      <img :src="showData.sickPicture"  class="wh100">
      <!-- <img src="https://image.giantgocloud.com/www/ImageMapping/image/20241120/96CB5FE0B75B4A7D8C14941230A9FAF3.png"
        class="wh100"> -->
    </div>
    <!-- <div class="testReport_pages13_content">
            <div class="pages13_main">
                <div class="pages13_list">
                    <div class="pages13_lsit_tilte">
                        症状:
                    </div>
                    <div class="pages13_lsit_test"  v-for="(item,index)  in showData.symptom" :key="index">
                        <span class="round"></span> {{ item }}
                    </div>
                </div>
                <div class="pages13_list" style="padding-left: 100px;">
                    <div class="pages13_lsit_tilte">
                        并发症:
                    </div>
                    <div class="pages13_lsit_test" v-for="(item,index)  in showData.complication" :key="index">
                        <span class="round"></span>{{ item }}
                    </div>
                </div>
            </div>
        </div> -->
    <div class="analysisReportPage" style="position: absolute;right: 60px;bottom: 60px;font-size: 20px;color: #000">
      {{ pageNum }} of {{ pageAllNum }}
    </div>
  </div>
</template>

<script>
import Head from './head.vue'
export default {
  props: ['objData', 'pageNum', 'pageAllNum'],
  data() {
    return {
      showData: '',
      sickNameText: ''
    }
  },
  methods: {

  },
  watch: {
    objData: {
      immediate: true,
      deep: true, // 开启深度监听 专门对付复杂数据类型
      handler: function (newValueA, oldValue) {
        this.showData = newValueA
        if (this.showData.sickName.includes('管理')) {
          this.sickNameText = this.showData.sickName.replace('管理', '')
        } else {
          this.sickNameText = this.showData.sickName
        }
      }
    }
  },
  components: {
    Head
  }
}
</script>

<style lang="scss" scoped>
.testReport_pages13 {
  .testReport_pages4_title_box {
    padding-left: 103px;
    padding-top: 30px;

    .title {
      font-weight: 600;
      font-size: 24px;
      color: #5aaca9;
    }

    .line {
      width: 100px;
      height: 3px;
      margin-top: 30px;
      background-color: #5aaca9;
    }
  }

  .testReport_text {
    height: 31px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    font-size: 17px;
    color: #474F57;
    line-height: 28px;
    padding: 20px 100px;
  }

  .testReport_img {
    width: 1000px;
    margin: 0 auto;
    margin-top: 40px;
    height: 1300px;
    overflow: hidden;
  }

  .testReport_pages13_content {
    margin-top: 20px;

    .pages13_main {
      display: flex;
      margin-left: 60px;

      .pages13_list {
        .pages13_lsit_tilte {
          width: 160px;
          height: 40px;
          border-radius: 26px;
          font-size: 21px;
          font-weight: 500;
          color: #33AEAA;
          padding-left: 22px;
        }

        .pages13_lsit_test {
          font-size: 16px;
          padding: 6px 0;
          padding-left: 20px;
          color: #474F57;

          .round {
            display: inline-block;
            width: 8px;
            height: 8px;
            border-radius: 4px;
            // background-color: #5aaca9;
            background-color: #4E4E4E;
            margin-right: 10px;
          }
        }
      }
    }

    .testReport_pages13_content_bg {
      position: absolute;
      width: 100%;
      height: 100%;
      overflow: hidden;
    }

    .testReport_pages13_content_text {
      margin-left: 186px;
      margin-top: 42px;
      font-size: 42px;
      font-weight: 500;
      color: #5aaca9;
    }

    .testReport_pages13_content_text1 {
      margin-left: 186px;
      margin-top: 90px;
      font-size: 22px;
      font-weight: 400;
      color: #474F57;
    }

    .testReport_pages13_content_ul {
      margin-top: 140px;

      .testReport_pages13_content_li {
        position: relative;
        margin-bottom: 60px;

        .pathogeny {
          width: 108px;
          height: 44px;
          background: url('https://image.giantgocloud.com/www/ImageMapping/image/20240808/F94D0784AB6D44668F2042EA773900B7.png');
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;

          .pathogeny_text {
            position: relative;
            top: -10px;
            font-family: Source Han Sans CN;
            font-size: 24px;
            color: #69B287;
            padding-left: 50px;
            margin-bottom: 20px;
          }
        }

        .testReport_pages13_content_li_title {
          margin-left: 162px;
          width: 578px;
          height: 50px;
          background: #5aaca9;
          border-radius: 26px;
          font-size: 28px;
          font-weight: 500;
          color: #FFFFFF;
          padding-left: 22px;
        }
      }

      .testReport_pages13_content_li_li {
        display: flex;
        align-items: center;
        margin-top: 20px;

        .testReport_pages13_content_li_li_left {
          margin-right: 60px;
          margin-left: 102px;
          width: 22px;
          height: 22px;
          background: #FFFFFF;
          border: 2px solid #5aaca9;
          border-radius: 50%;
        }

        .testReport_pages13_content_li_li_text {
          font-size: 22px;
          font-weight: 400;
          color: #474F57;
        }
      }
    }
  }
}
</style>
