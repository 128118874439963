<!-- 精准 -->
<template>
  <div class='recipes'>
    <div class="funMedicine coverTitle pdfHeight" style="margin-top: 0;">
      <div class="head_img">
        <img style="width: 100%;height: 100%;"
          src="https://image.giantgocloud.com/www/ImageMapping/image/20241119/CBECD35585034C5C849FB48315CE82C5.png"
          alt="">
        <div class="analysisReport_home_info1_title">
          健康数据精准分析
        </div>
        <div class="analysisReport_home_info1_en"> Precise Analysis Report</div>
      </div>
      <div v-if="listData.reportUserInfo" class="messageinfo">
        <div>姓名 / Compellation：&nbsp;&nbsp;{{ listData.reportUserInfo.Name }}</div>
        <div>性别 / Gender：&nbsp;&nbsp;{{ listData.reportUserInfo.Sex }}</div>
        <div>年龄 / Age：&nbsp;&nbsp;{{ listData.reportUserInfo.Age }}</div>
        <div>出生日期 / Date of birth：&nbsp;&nbsp;{{ listData.reportUserInfo.Birth }}</div>
        <div>报告日期 / Report date：&nbsp;&nbsp;{{ listData.reportUserInfo.CheckDate }}</div>
      </div>
      <div class="first_bottom">
        <div >
          <div style="margin-left: 80px;width: 73px;height: 43px;">
            <img
            style="width: 100%;height: 100%;"
              :src="listData.reportUserInfo.logoImageUrl"
              alt="">
          </div>
          <div style="width: 246px;height: 24px;">
            <img
              style="width: 100%;height: 100%;"
              src="https://image.giantgocloud.com/www/ImageMapping/image/20241119/5FFF92995CE84EB698637D55B6B38AB5.png"
              alt="">
          </div>
        </div>
      </div>
    </div>
    <!-- 目录 -->
    <div v-if="dataList">
      <directory :alNum="alNum" :numPageA="numPageA" :Sex="Sex" :directoryList="listData.finalAnalysisSickItem" />
    </div>
    <div class="funMedicine zZjpagesNum pdfHeight" v-if="dataList">
      <Level :alNum="alNum" :numPageA="numPageB" :Sex="Sex" :LevelDetails="listData.LevelDetails" />
    </div>
    <div style='position: relative;box-sizing: border-box;overflow: hidden;'
      :style="'height:' + (pageListPage * 842) + 'px'">
      <div class="pageHeightCss pdfHeight" v-for="indexAll in pageListPage" :key="indexAll">
        <div class="funMedicinesecod_line" v-if="dataList">

          <Head />
        </div>
        <div style="position: absolute;left: 530px;bottom: 30px;;font-size: 10px;color: #000">
          {{ indexAll + 2 }} of {{ alNum - 1 }}
        </div>
      </div>
      <template v-if="listData?.indicatorsCategoryList && listData.indicatorsCategoryList.length">
        <table v-for="(item, index) in listData.indicatorsCategoryList" class="personalUltimatePdf_table" :key="index">
          <tr class="personalUltimatePdf_table_tr" style="color: #fff">
            <div v-if="index == 0" class="Level_title">
              您的指标概览
            </div>
            <div class="indexlist_title">
              {{ item.categoryName }}
            </div>
            <th class="th_class" style="flex: 160%;">指标</th>
            <th style="background-color: #fff;width: 2px;"></th>
            <th class="th_class" style="flex: 60%;">等级</th>
            <th style="background-color: #fff;width: 2px;"></th>
            <th class="th_class" style="flex: 60%;">结果</th>
            <th style="background-color: #fff;width: 2px;"></th>
            <th class="th_class">单位</th>
            <th style="background-color: #fff;width: 2px;"></th>
            <th class="th_class">参考</th>
            <th style="background-color: #fff;width: 2px;"></th>
            <th class="th_class" style="flex: 220%;">指标影响哪些健康问题</th>
          </tr>
          <tr class="personalUltimatePdf_table_tr" v-for="(item5, index5) in item.indicatorsList" :key="index5">
            <template v-if="item5.checkResult">
              <td class="IndicatorListCheckItem" style="flex: 160%;">{{ item5.indicatorsName }}</td>
              <td style="background-color: #fff;width: 2px;"></td>
              <td class="IndicatorListCheckItem" style="flex: 60%;">
                <div class="IndicatorListCheckItem_level" :style="{ background: item5.colorCode }">
                  {{ item5.colorLevel }}
                </div>
              </td>
              <td style="background-color: #fff;width: 2px;"></td>
              <td class="IndicatorListCheckItem" style="flex: 60%;">{{ item5.checkResult }}</td>
              <td style="background-color: #fff;width: 2px;"></td>
              <td class="IndicatorListCheckItem">{{ item5.indicatorsUnit }}</td>
              <td style="background-color: #fff;width: 2px;"></td>
              <td class="IndicatorListCheckItem">{{ item5.minRefence }}-{{ item5.maxRefence }}</td>
              <td style="background-color: #fff;width: 2px;"></td>
              <td class="IndicatorListCheckItem" style="flex: 220%;">{{ item5.relatedHealth }}</td>
            </template>
          </tr>
          <div style="height: 50px;"></div>
        </table>
      </template>
      <div class="Z_personalUltimatePdf" v-for="(item, index) in listData.finalAnalysisDataDetailTitleDtos"
        :key="index">
        <div v-if="item.indicatorsList && item.indicatorsList.length" class="Z_personalUltimatePdf_head">
          <div style="display: flex;background-color: #5aaca9; position: relative;">
            <div class="repair1">
              {{ item.Theme }}
            </div>
            <div v-if="item.themeLevel" class="Level_T" :style="{ backgroundColor: item.themeColorCode }">{{
              item.themeLevel
            }}</div>
          </div>
          <div v-if="item.ThemeDescribe" class="repair2">
            <div>
              {{ item.ThemeDescribe }}
            </div>
          </div>
        </div>
        <template v-for="(item2, index2) in item.indicatorsList">
          <div class="funMedicine6_box" :key="index2">
            <div class="funMedicine6_box_ii" style="position: relative;left:-10px">
              <div style="position: relative;left:10px" class="funMedicine6HeadTop">
                <div>
                  <div style="position: relative;color: #5a63a4;" class="funMedicine6Text">
                    {{ item2.indicatorsName }}：
                    <img style="position: absolute;width: 229px;left: -48px; top: -15px;"
                      src="https://image.giantgocloud.com/www/ImageMapping/image/20240930/2772FFB0B82D40E3873E6431DA23DDBE.png"
                      mode="widthFix" />
                    <div class="funMedicine6Text_hx">

                    </div>
                    <div class="a_flex"
                      style="width: 20px;height: 20px;border-radius: 5px;position: relative;left: 175px;top:-20px;"
                      :style="{ background: `${hexToRgb(item2.colorCode, 0.1)}`, border: `1px solid ${item2.colorCode}`, color: `${item2.colorCode}` }">
                      {{ item2.colorLevel }}
                    </div>
                  </div>
                  <div class="problem" style="margin-top: -10px;">
                    <div class="problemText">
                      {{ item2.indicatorsDescription }}
                    </div>
                  </div>
                </div>
                <div class="process" style="margin-top: 25px;" id="processdata">
                  <div style="display: flex;" v-for="(color, index) in item2.colorList" :key="index">
                    <div class="process1" :class="index == item2.colorList.length - 1 ? 'process1_A' : ''" :style="{
                      width: `${(200 / item2.colorList.length).toFixed(1) + 'px'}`,
                      background: `${color.colorCode}`,
                    }">
                      <span style="font-size: 60%;position: relative;top: -28px;">{{ color.colorName }}</span>
                    </div>
                  </div>
                  <div style="position: absolute;display: inline-block;"
                    :style="{ top: `8px`, left: `${((item2.calcResult / 100) * 200).toFixed(1) + 'px'}` }">
                    <div class="wh100" style="position: relative;transform: translateX(-50%);">
                      <div class="Yuan a_flex" style="position: relative;z-index: 2;letter-spacing: 1px;"
                        :style="{ background: ` ${item2.colorCode}` }">YOU:{{ item2.checkResult }}{{
                          item2.indicatorsUnit
                        }}</div>
                      <div :style="{ background: ` ${item2.colorCode}` }"
                        style="width: 4px;height:4px;position: absolute;z-index: 1;top:-2px;left: 50%;transform: translateX(-2px) rotate(45deg);">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>

    </div>
  </div>
</template>
<script>
import axios from 'axios'
// import { GetFinalAnalysisReport } from '@/api/index'
// import Repair from './componetPdf/repair'
// import RepairTwos from './componetPdf/repairTwos.vue'
// import RepairThree from './componetPdf/repairThree.vue'
// import Cardiovascular from './componetPdf/cardiovascular.vue'
// import Metabolism from './componetPdf/metabolism.vue'
// import Inflammation1 from './componetPdf/inflammation1.vue'
// import Inflammation2 from './componetPdf/inflammation2.vue'
// import Sleep from './componetPdf/sleep.vue'
// import Endurance from './componetPdf/endurance.vue'
// import Hormone1 from './componetPdf/hormone1.vue'
// import Hormone2 from './componetPdf/hormone2.vue'
// import Cognition from './componetPdf/cognition.vue'
// import Homepage1 from './componetPdf/homepage1.vue'
// import Homepage2 from './componetPdf/homepage2.vue'
import Directory from './componetPdf/directory.vue'
import Indicator from './componetPdf/Indicator.vue'
import Level from './componetPdf/Level.vue'
import Head from './componetPdf/head.vue'
import H5Report from '@/api/index'
export default {
  data() {
    return {
      pageListPageLeftHeight: 0,
      pageListPagerightHeight: 0,
      alNum: 0,
      listData: [],
      titleList: [],
      titleListIndex: 0,
      reportUserInfo: '',
      finalAnalysisSickItem: [],
      finalAnalysisDataDetailTitleDtos: [],
      newlistData: [],
      Sex: '',
      headerData: {
        pageCount: 0,
        head: '',
        resultArray: []
      },
      // list3: [],
      IndicatorList: [],
      coverManImg: 'https://image.giantgocloud.com/www//ImageMapping/image/20230810/98DA1027B2244A42AD2A1821B6E9D48C.png',
      coverGirlImg: 'https://image.giantgocloud.com/www//ImageMapping/image/20230810/F47180B6A32243E7A4583CB59C2BF8AB.png',
      numPageA: 0,
      numPageB: 3,
      dataList: '',
      pageList: [],
      pageListPage: 1,
      pageListPageHeight: 0
    }
  },
  components: {
    Head,
    Directory,
    Indicator,
    Level
  },
  created() {
    const that = this
    const id = this.$query('id')
    that.listData = {}
    if (localStorage.getItem('listDataPdfZ')) {
      that.listData = JSON.parse(localStorage.getItem('listDataPdfZ'))
      console.log('that.listData', that.listData)
      that.numPageB = localStorage.getItem('numPageB')
      setTimeout(() => {
        localStorage.removeItem('listDataPdfZ')
        localStorage.removeItem('alNum')
      }, 1000)
    } else {
      this.getCustomerFoodWayResult(id)
    }
    console.log('that.listData', that.listData)
  },
  mounted() {
    const that = this
  },
  methods: {
    hexToRgb(hex, opt) {
      // Remove "#" symbol from the beginning of the string
      hex = hex.replace('#', '')
      // Convert each pair of characters to decimal values for red, green, and blue
      var red = parseInt(hex[0] + hex[1], 16)
      var green = parseInt(hex[2] + hex[3], 16)
      var blue = parseInt(hex[4] + hex[5], 16)
      return 'rgba(' + red + ',' + green + ',' + blue + ',' + opt + ')'
    },
    numPageAF(e) {
      console.log('接受之', e)
      this.numPageA = e
    },
    // splitArray(array, size) {
    //   let data = [];
    //   for (let i = 0; i < array.length; i += size) {
    //     data.push(array.slice(i, i + size))
    //   }
    //   this.newlistData.push(data)
    //   console.log(data,'data')
    //   console.log(this.newlistData,'newdata')
    // },
    dataFormats(data, userInfo, dataList) {
      console.log('data', data)
      const formats = {
        reportUserInfo: {
          Name: userInfo.userName,
          Sex: userInfo.sex,
          Age: userInfo.userAge,
          Birth: userInfo.userBirthDay,
          CheckDate: userInfo.checkDate,
          logoImageUrl: userInfo.logoImageUrl
        },
        LevelDetails: [],
        finalAnalysisDataDetailTitleDtos: [],
        finalAnalysisSickItem: [],
        indicatorsList: dataList.indicatorsList,
        indicatorsCategoryList: dataList.indicatorsCategoryList
      }
      data.map((v, i) => {
        formats.LevelDetails.push({
          Level: v.themeLevel,
          Theme: v.themeName,
          themeColorCode: v.themeColorCode
        })
        formats.finalAnalysisDataDetailTitleDtos.push({
          Theme: v.themeName,
          themeLevel: v.themeLevel,
          themeColorCode: v.themeColorCode,
          ThemeDescribe: v.themeDescription,
          indicatorsList: v.indicatorsList,
          finalAnalysisDataDetailDtos: v.indicatorsList.map((v1, i1) => {
            return {
              DetailCheckItem: v1.indicatorsName,
              DetailCheckItemDescent: v1.indicatorsDescription
            }
          })
        })
        formats.finalAnalysisSickItem.push({
          SickTitle: v.themeName,
          SickTitleDescribe: v.themeDescription,
          ThemePicUrl: v.themePicUrl,
          SickTitleDetail: v.indicatorsList.map(v1 => {
            return {
              CheckItem: v1.indicatorsName,
              CheckItemDetail: v1.indicatorsDescription,
              CheckResultUnit: v1.indicatorsUnit,
              CheckResultValue: v1.checkResult,
              minRefence: v1.minRefence,
              maxRefence: v1.maxRefence,
              OtherDescribe: v1.colorName,
              RoundColorNum: v1.colorCode,
              Level: v1.colorLevel,
              ColorValuePercent: v1.calcResult,
              ColorLengths: v1.colorList.map(v2 => {
                return {
                  ColorName: v2.colorName,
                  ColorNum: v2.colorCode,
                  ColorLevel: v2.colorLevel
                }
              })
            }
          })
        })
      })
      return formats
    },
    getCustomerFoodWayResult(id) {
      const that = this
      axios
        .post(
          `${H5Report}/boardApi/UpLoadReport/GetAnalysisReport`, {
          ReportId: id
        }
        )
        .then((res) => {
          console.log('接口返回值', res)
          window.sessionStorage.setItem('reportUserInfo', JSON.stringify(res.data.data.userInfo))
          this.reportUserInfo = JSON.parse(JSON.stringify(res.data.data.userInfo))
          that.listData = this.dataFormats(res.data.data.dataList.themeList, res.data.data.userInfo, res.data.data.dataList)
          console.log('that.listData.finalAnalysisDataDetailTitleDtos', that.listData.finalAnalysisDataDetailTitleDtos)
          that.dataList = res.data.data.dataList
          that.pageList = []
          that.pageListPage = 1
          that.pageListPageHeight = 40
          const contentHeight = 700
          setTimeout(() => {
            const personalUltimatePdfTable = document.getElementsByClassName('personalUltimatePdf_table')
            // console.log('personalUltimatePdfTable', personalUltimatePdfTable)
            if (personalUltimatePdfTable && personalUltimatePdfTable.length) {
              for (let i = 0; i < personalUltimatePdfTable.length; i++) {
                if (contentHeight - that.pageListPageHeight < 70) {
                  that.pageListPage++
                  const divD = {
                    pageNum: that.pageListPage,
                    top: 0,
                    left: 45
                  }
                  personalUltimatePdfTable[i].style.position = 'absolute'
                  personalUltimatePdfTable[i].style.top = divD.top + ((that.pageListPage - 1) * 842) + 94 + 'px'
                  personalUltimatePdfTable[i].style.left = divD.left + 'px'
                  that.pageList.push(divD)
                  that.pageListPageHeight = personalUltimatePdfTable[i].offsetHeight
                  console.log('11111111', contentHeight, that.pageListPageHeight, personalUltimatePdfTable[i].offsetHeight)
                } else if (personalUltimatePdfTable[i].offsetHeight + that.pageListPageHeight + 20 < contentHeight) {
                  const divD = {
                    pageNum: that.pageListPage,
                    top: that.pageListPageHeight,
                    left: 45
                  }
                  personalUltimatePdfTable[i].style.position = 'absolute'
                  personalUltimatePdfTable[i].style.top = divD.top + ((that.pageListPage - 1) * 842) + 94 + 'px'
                  personalUltimatePdfTable[i].style.left = divD.left + 'px'
                  that.pageList.push(divD)
                  that.pageListPageHeight = that.pageListPageHeight + personalUltimatePdfTable[i].offsetHeight + 0
                } else {
                  const personalUltimatePdfTableTr = personalUltimatePdfTable[i].getElementsByClassName('personalUltimatePdf_table_tr')
                  // console.log('personalUltimatePdfTableTr', personalUltimatePdfTableTr)
                  that.pageListPageHeight = that.pageListPageHeight + 0
                  for (let q = 0; q < personalUltimatePdfTableTr.length; q++) {
                    if (personalUltimatePdfTableTr[q].offsetHeight + that.pageListPageHeight < contentHeight) {
                      const divD = {
                        pageNum: that.pageListPage,
                        top: that.pageListPageHeight,
                        left: 45
                      }
                      personalUltimatePdfTableTr[q].style.position = 'absolute'
                      personalUltimatePdfTableTr[q].style.top = divD.top + ((that.pageListPage - 1) * 842) + 94 + 'px'
                      personalUltimatePdfTableTr[q].style.left = divD.left + 'px'
                      that.pageList.push(divD)

                      that.pageListPageHeight = that.pageListPageHeight + personalUltimatePdfTableTr[q].offsetHeight
                    } else {
                      that.pageListPage++
                      const divD = {
                        pageNum: that.pageListPage,
                        top: 0,
                        left: 45
                      }
                      personalUltimatePdfTableTr[q].style.position = 'absolute'
                      personalUltimatePdfTableTr[q].style.top = divD.top + ((that.pageListPage - 1) * 842) + 94 + 'px'
                      personalUltimatePdfTableTr[q].style.left = divD.left + 'px'
                      that.pageList.push(divD)
                      that.pageListPageHeight = personalUltimatePdfTableTr[q].offsetHeight
                    }
                  }
                  that.pageListPageHeight = that.pageListPageHeight + 40
                }
              }
            }
            // indexList
            const ZPersonalUltimatePdf = document.getElementsByClassName('Z_personalUltimatePdf')
            for (let i = 0; i < ZPersonalUltimatePdf.length; i++) {
              const ZPersonalUltimatePdfHead = ZPersonalUltimatePdf[i].getElementsByClassName('Z_personalUltimatePdf_head')
              // 头部
              that.pageListPagerightHeight = that.pageListPageHeight
              if (ZPersonalUltimatePdfHead && ZPersonalUltimatePdfHead.length) {
                that.pageListPagerightHeight = 0

                if (ZPersonalUltimatePdfHead[0].offsetHeight + that.pageListPageHeight + 20 < contentHeight) {
                  that.pageListPage++
                  console.log('1111')
                  const divD = {
                    pageNum: that.pageListPage,
                    top: 0,
                    left: 20
                  }
                  ZPersonalUltimatePdfHead[0].style.position = 'absolute'
                  ZPersonalUltimatePdfHead[0].style.top = divD.top + ((that.pageListPage - 1) * 842) + 94 + 'px'
                  ZPersonalUltimatePdfHead[0].style.left = divD.left + 'px'
                  that.pageList.push(divD)
                  that.pageListPageLeftHeight = ZPersonalUltimatePdfHead[0].offsetHeight
                  that.listData.finalAnalysisSickItem[i].pageNum = that.pageListPage + 3
                } else {
                  that.pageListPagerightHeight = 0
                  that.pageListPage++
                  const divD = {
                    pageNum: that.pageListPage,
                    top: 0,
                    left: 20
                  }
                  ZPersonalUltimatePdfHead[0].style.position = 'absolute'
                  ZPersonalUltimatePdfHead[0].style.top = divD.top + ((that.pageListPage - 1) * 842) + 94 + 'px'
                  ZPersonalUltimatePdfHead[0].style.left = divD.left + 'px'
                  that.pageList.push(divD)
                  that.pageListPageLeftHeight = ZPersonalUltimatePdfHead[0].offsetHeight
                  that.listData.finalAnalysisSickItem[i].pageNum = that.pageListPage + 3
                }
              }
              // 此处修改左右
              const ZFunMedicine6Box = ZPersonalUltimatePdf[i].getElementsByClassName('funMedicine6_box')
              for (let q = 0; q < ZFunMedicine6Box.length; q++) {
                if (that.pageListPagerightHeight < that.pageListPageLeftHeight) {
                  if (ZFunMedicine6Box[q].offsetHeight + that.pageListPagerightHeight < contentHeight) {
                    const divD = {
                      pageNum: that.pageListPage,
                      top: that.pageListPagerightHeight,
                      left: 340
                    }
                    ZFunMedicine6Box[q].style.position = 'absolute'
                    ZFunMedicine6Box[q].style.top = divD.top + ((that.pageListPage - 1) * 842) + 94 + 'px'
                    ZFunMedicine6Box[q].style.left = divD.left + 'px'
                    that.pageList.push(divD)
                    that.pageListPagerightHeight = ZFunMedicine6Box[q].offsetHeight + that.pageListPagerightHeight
                  } else {
                    that.pageListPage++
                    const divD = {
                      pageNum: that.pageListPage,
                      top: 0,
                      left: 50
                    }
                    ZFunMedicine6Box[q].style.position = 'absolute'
                    ZFunMedicine6Box[q].style.top = divD.top + ((that.pageListPage - 1) * 842) + 94 + 'px'
                    ZFunMedicine6Box[q].style.left = divD.left + 'px'
                    that.pageList.push(divD)
                    that.pageListPageLeftHeight = ZFunMedicine6Box[q].offsetHeight
                    that.pageListPagerightHeight = 0
                  }
                } else {
                  // console.log('0000', ZFunMedicine6Box[q].offsetHeight + that.pageListPageLeftHeight < contentHeight)
                  if (ZFunMedicine6Box[q].offsetHeight + that.pageListPageLeftHeight < contentHeight) {
                    const divD = {
                      pageNum: that.pageListPage,
                      top: that.pageListPageLeftHeight,
                      left: 50
                    }
                    ZFunMedicine6Box[q].style.position = 'absolute'
                    ZFunMedicine6Box[q].style.top = divD.top + ((that.pageListPage - 1) * 842) + 94 + 'px'
                    ZFunMedicine6Box[q].style.left = divD.left + 'px'
                    that.pageList.push(divD)
                    that.pageListPageLeftHeight = ZFunMedicine6Box[q].offsetHeight + that.pageListPageLeftHeight
                  } else {
                    that.pageListPage++
                    const divD = {
                      pageNum: that.pageListPage,
                      top: 0,
                      left: 50
                    }
                    ZFunMedicine6Box[q].style.position = 'absolute'
                    ZFunMedicine6Box[q].style.top = divD.top + ((that.pageListPage - 1) * 842) + 94 + 'px'
                    ZFunMedicine6Box[q].style.left = divD.left + 'px'
                    that.pageList.push(divD)
                    that.pageListPageLeftHeight = ZFunMedicine6Box[q].offsetHeight
                    that.pageListPagerightHeight = 0
                  }
                }
              }
              if (that.pageListPageLeftHeight > that.pageListPagerightHeight) {
                that.pageListPageHeight = that.pageListPageLeftHeight
              } else {
                that.pageListPageHeight = that.pageListPagerightHeight
              }
            }
            this.alNum = that.pageListPage + 3
          }, 500)
        })
    }
  }

}
</script>
<style lang='scss' scoped>
.head_img {
  position: relative;
  width: 612px;
  height: 515px;
  margin-top: 40px;
  .analysisReport_home_info1_title {
    position: absolute;
    z-index: 9999;
    left: 188px;
    top: 390px;
    width: 265px;
    height: 37px;
    line-height: 37px;
    background: #fff;
    border-radius: 38px;
    font-size: 24px;
    color: #33AEAA;
    text-align: center;
    font-weight: bold;
  }

  .analysisReport_home_info1_en {
    position: absolute;
    z-index: 9999;
    left: 168px;
    top: 432px;
    font-size: 24px;
    color: #fff;
  }
}

.overview {
  color: blue
}

.pageHeightCss {
  position: relative;
  overflow: hidden;
}

.Level_T {
  position: absolute;
  top: 6px;
  left: 214px;
  background: '#b0b1b7';
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 20px;
  border-radius: 4px 4px 4px 4px;
  font-size: 15px;
  color: #fff;
  opacity: 1;
}

.table-wrapper {
  overflow: hidden;

  width: 509px;
  height: 690px;
  margin: auto;
  overflow-x: scroll;
  font-size: 70%;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #4e4e4e;
  line-height: 26px;
  -webkit-background-clip: text;
}

.personalUltimatePdf_table {
  text-align: center;
  width: 509px;
  border-collapse: collapse;

  tr {
    width: 509px;
    height: 33px;
    font-size: 140%;
  }

  tr:nth-child(even) {
    background: #ebecec;
    opacity: 1;
  }

  tr:nth-child(odd) {
    // background: red;
    // opacity: 1;
  }

  tr:nth-child(1) {
    background: #33AEAA;
    opacity: 1;
    font-size: 140% !important;
  }

  .IndicatorListCheckItem {
    color: #4e4e4e;
    font-size: 60%;

    .IndicatorListCheckItem_level {
      width: 20px;
      height: 20px;
      line-height: 20px;
      border-radius: 2px;
      color: #fff;
    }
  }

  .IndicatorListCheckasult {
    font-size: 10px;
  }

  .IndicatorListCheckstatus {
    font-size: 10px;
  }

  .IndicatorListCheckgrade {
    font-size: 10px;
  }

  .funMedicine5 {
    position: relative;
    overflow: hidden;

    .funMedicine5Cloumns {
      display: inline-block;
      width: 4px;
      height: 54px;
      margin-top: 64px;
      margin-left: 45px;
      background: linear-gradient(117deg,
          #1386b0 0%,
          rgba(19, 134, 176, 0) 100%);
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
    }

    .funMedicine5Text {
      margin-bottom: 30px;
      margin-left: 8px;
      display: inline-block;

      .funMedicine5TextTop {
        // width: 306px;
        height: 26px;
        font-size: 24px;
        font-family: PingFang SC-Semibold, PingFang SC;
        font-weight: 600;
        color: #1386b0;
        line-height: 26px;
        -webkit-background-clip: text;
      }

      .funMedicine5TextBottom {
        margin-top: 8px;
        width: 162px;
        height: 26px;
        font-size: 24px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #4e4e4e;
        line-height: 26px;
        letter-spacing: 1px;
        -webkit-background-clip: text;
      }
    }
  }

  .Yuan {
    position: absolute;
    display: inline-block;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: white;
  }

  .processdata {
    width: 150px;

    .process {
      position: relative;
      display: flex;
      justify-content: center;
      text-align: center;
      width: 150px;

      // margin: 30px 0;
      span {
        display: inline-block;
        transform: translateY(10px);
        margin-top: 5px;
      }

      .process1 {
        // width: 100px;
        height: 3px;
        background: #5ec692;
        opacity: 1;
      }

      .process1:nth-of-type(1) {
        height: 3px;
        background: #d51931;
        opacity: 1;
      }

      .process1:nth-last-of-type(1) {
        height: 3px;
        background: #e29a30;
        opacity: 1;
      }

      .process1_A {
        height: 3px;
        background: #e29a30;
        opacity: 1;
      }

    }

    .Yuan {
      position: absolute;
      display: inline-block;
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background: white;
    }
  }
}

.recipes {
  width: 595px;
  letter-spacing: 2px;

  .funMedicine {
    width: 595px;
    position: relative;
    box-sizing: border-box;
    overflow: hidden;

    .page {
      position: absolute;
      width: 40px;
      height: 40px;
      background: #1386b0;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
      right: 0px;
      bottom: -10px;
      background: url("../../assets/analysis/be9299d8ce7aecbcc66619a0dd3f403.png") no-repeat;
      background-size: contain;
      text-align: center;
      font-size: 13px;
      padding-left: 2px;
      box-sizing: border-box;
      font-family: DIN-Bold, DIN;
      font-weight: bold;
      color: #ffffff;
      line-height: 33px;
    }
  }

  .coverTitle {
    margin-top: 64px;

    .coverTitleTop {
      width: 76.06px;
      height: 74.22px;
      margin: 62px auto 0 auto;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .coverTitle_title {
      width: 359px;
      height: 142.35px;
      margin: 58px auto;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .coverTitle_content {
      width: 498.56px;
      height: 127.56px;
      margin: 0 auto;

      img {
        width: 100%;
        height: 100%;
      }
    }
    .messageinfo {
      width: 498.56px;
      margin: 0 auto 0 auto !important;

      div {
        margin: 7px;
        width: 100%;
        height: 28px;
        background: #EFEFEF;
        opacity: 1;
        line-height: 28px;
        text-align: center;
      }
    }

    .first_bottom {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 30px;
      .first_bottom1 {
        width: 42.98px;
        height: 5.45px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .first_bottom2 {
        font-size: 8px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #9FA0A0;
        line-height: 9px;
      }
    }
  }

  .funMedicinesecod {
    .funMedicinesecodImg {
      width: 537px;
      height: 420px;
      margin: 0 auto;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .funMedicinesecodContend {
      width: 500px;
      margin: 0 auto;

      .funMedicinesecodContend1 {
        font-weight: 600;
        color: #000;
        font-size: 12px;
      }

      .funMedicinesecodContend2 {
        color: #4E4E4E;
        font-size: 12px;
        margin: 16px 0;
      }

      .funMedicinesecodContend2 {
        color: #4E4E4E;
        font-size: 12px;
        margin: 16px 0;
      }
    }
  }

}

.funMedicine6_box {
  // width: 229px;
  // margin: 20px 0;
  display: inline-block;
  position: relative;
  padding-bottom: 25px;

  .funMedicine6_box_ii {
    border-right: 2px solid #eeeeee;
    border-bottom: 2px solid #eeeeee;
    border-radius: 10px;
    padding-bottom: 20px;
  }
}

.funMedicine6HeadTop {
  margin-top: 10px;
  display: inline-block;
}

#processdata {}

.funMedicine6Head {
  margin-top: 58px;
  // margin-left: 44px;
  // width: 367px;
  font-size: 14px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #4e4e4e;
  line-height: 26px;
  // letter-spacing: 1px;

  span {
    color: #1386b0;
  }
}

.funMedicine6Head:nth-of-type(1) {
  margin-top: 0
}

.funMedicine6Footer {
  position: absolute;
  width: 40px;
  height: 40px;
  background: #1386b0;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  right: 0px;
  bottom: -10px;
  /* background: url("../../assets/analysis/be9299d8ce7aecbcc66619a0dd3f403.png") no-repeat; */
  background-size: contain;
  text-align: center;
  font-size: 13px;
  padding-left: 2px;
  box-sizing: border-box;
  font-family: DIN-Bold, DIN;
  font-weight: bold;
  color: #ffffff;
  line-height: 33px;
}

.funMedicine6Cloumns {
  // margin-top: 12px;
  // margin-left: 44px;
  width: 122px;
  height: 4px;
  // background: linear-gradient(117deg, #1386B0 0%, rgba(19, 134, 176, 0) 100%);
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  // margin: 10px 0;
}

.funMedicine6Num {
  width: 57px;
  height: 20px;
  font-size: 12px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #4e4e4e;
  line-height: 24px;
  margin-top: 20px;
  -webkit-background-clip: text;
}

.funMedicine6Text {
  margin-top: 5px;
  width: 229px;
  // height: 30px;
  font-size: 13px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #1386b0;
  // line-height: 30px;
  letter-spacing: 1px;
  // -webkit-background-clip: text;
  // vertical-align: middle;
}

.process {
  position: relative;
  display: flex;
  // justify-content: center;
  text-align: center;
  margin-top: 30px;

  span {
    display: inline-block;
    transform: translateY(10px);
    margin-top: 5px;
  }

  .process1 {
    // width: 100px;
    height: 4px;
    background: #5ec692;
    opacity: 1;
  }

  .process1:first-child {
    // width: 100px;
    height: 4px;
    background: #d51931;
    opacity: 1;
    border-radius: 0px 0px 0px 0px;
  }

  .process1:last-child {
    // width: 100px;
    height: 4px;
    background: #e29a30;
    opacity: 1;
  }
}

.Yuan {
  padding: 4px 4px;
  width: 70px;
  padding: 2px 1px;
  background: white;
  color: #fff;
  font-size: 60%;
}

.problem {
  width: 210px;
  // margin-left: 45px;
  font-size: 90%;
  line-height: 12px;
  // margin-top: 8px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;

  .problemText {
    position: relative;
    left: -5px;
    color: #4e4e4e;

    span {
      color: #1386b0;
      margin-right: 3px;
    }
  }
}

.problem:nth-child(1) {
  // margin-top: 24px;
}

.footers {
  margin-top: 12px;
  margin-left: 45px;
  color: #1386b0;
}

.processA {
  display: inline-flex !important;
}

.personalUltimatePdf_table_tr {
  position: relative;
  z-index: 9999;
  display: flex;

  th {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  td {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .th_class {
    font-size: 70%;
    font-weight: 500;
  }

}

.Level_title {
  width: 124px;
  position: absolute;
  left: -35px;
  top: -116px;
  background-color: #33AEAA;
  font-size: 100%;
  margin-top: 56px;
  margin-left: 36px;
  font-weight: 600;
  color: #fff;
  line-height: 26px;
  letter-spacing: 1px;
  border-radius: 6px;
}

.indexlist_title {
  position: absolute;
  left: 0;
  top: -22px;
  font-size: 10px;
  color: #4e4e4e;
}

.Z_personalUltimatePdf_head {
  width: 250px;
  border-radius: 10px;
  overflow: hidden;

  .repair1 {
    letter-spacing: 0px;
    font-size: 15px;
    color: #fff;
    border-radius: 5px;
    padding: 5px 8px;
    margin-left: 25px;
  }

  .repair2 {
    font-size: 90%;
    color: #4E4E4E;
    padding: 0 25px;
    padding-bottom: 25px;
    padding-top: 10px;
    border: 1px solid #eeeeee;
    border-radius: 10px;
  }
}

.funMedicine6Text_hx {
  position: absolute;
  width: 202px;
  height: 1px;
  background-color: #7a80b1;
  left: -32px;
  top: 17px;
}

.analysisReport_home_info1 {
  display: flex;
  justify-content: center;
  margin-top: 88px;

  .analysisReport_home_info1_l {
    width: 3px;
    height: 30px;
    background-color: #474F57;
  }

  .analysisReport_home_info1_text {
    width: 320px;
    height: 37px;
    margin: 0 12px;
  }
}
</style>
