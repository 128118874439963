<template>
  <div v-if="objData" style="position: relative;">
    <div v-for="index in pagesListNum" class="pageSize" style="position: relative;">

      <Head text="健康管理方案" bgColor="#5aaca9"></Head>
      <div class="analysisReportPage" style="position: absolute;right: 60px;bottom: 60px;font-size: 20px;color: #000">
        {{ pageNum + index }} of {{ pageAllNum }}
      </div>
    </div>
    <div class="analysisReport_box11" style="position: absolute;top: 0px;">
      <div class="analysisReport_box11_title zdwPageDiv">
        <div class="analysisReport_box11_title_text">
          健康管理建议
        </div>
      </div>
      <!-- <div style="font-size: 26px;color: #475057;margin-top: 40px;">
        1、健康管理方向和阶段建议
      </div>
      <div class="analysisReport_box11_S6_ul">
        <div v-for="(item, index) in stageHealthyManageList" :key="index" class="analysisReport_box11_S6_li">
          <div class="analysisReport_box11_S6_li_A a_flex" style="position: relative;">
            <img class="wh100" style="position: absolute;top: -3px;"
              src="https://image.giantgocloud.com/www/ImageMapping/image/20240930/EC3F42F2A5B046FBAFB9997C2D0E4BD8.png"
              alt="" />
            <div style="position: relative;z-index: 1;color: #fff;top: -3px;">
              {{ item.title }}
            </div>
          </div>
          <div v-for="(item1, index1) in item.troubleList" :key="index1"
            :style="'border:1px solid ' + item1.projectColorCode" class="analysisReport_box11_S6_li_B">
            <div class="analysisReport_box11_S6_li_B_d"></div>
            {{ item1.projectName }}
          </div>
        </div>
      </div> -->
      <template v-if="showData">
        <div class="title customProductListTId zdwPageDiv" style="padding-top: 80px;">
          1、健康管理方案建议
        </div>
        <!-- <template v-for="(item, index) in productList"> -->
        <div class="food_num customProductListTId zdwPageDiv" v-for="(item1, index2) in productList">
          <div class="productTitle"> {{ item1.showTitle }}</div>
          <div class="productContent">
            <div class="productContent_left">
              <div class="productContent_left_name">
                产品名称：{{ item1.showProductName }}
              </div>
              <div class="productContent_line"></div>
              <div class="productContent_left_effect" v-for="(item2, index2) in item1.effect">
                <div class="effect_img">
                  <img
                    src="https://image.giantgocloud.com/www/ImageMapping/image/20240606/13D5196D44294522A8D00F420E2FE084.png"
                    alt="" style="width: 100%;height: 100%;">
                </div>
                <div class="effect_text">
                  {{ item2.effect }}<span v-if="item2.ingredient">:</span>{{ item2.ingredient }}
                </div>
              </div>
            </div>
            <div class="productContent_right">
              <img :src="item1.productPicture" alt="" class="img">
            </div>
          </div>
        </div>
        <!-- </template> -->
      </template>
      <div class="title customProductListTId zdwPageDiv" style="padding-top: 80px;">
        {{ showData ? 2 : 1 }}、个性化营养定制方案
      </div>
      <div class="text customProductListTId zdwPageDiv" style="padding-top: 10px;">
        {{ objData.singleAnalysisInModel?.productModel.adviceText }}
      </div>
      <template v-for="(item, index) in objData.singleAnalysisInModel?.productModel?.customProductList">
        <div class="food ">
          <div class="food_num customProductListTId zdwPageDiv">第{{ index == 0 ? '一' : index == 1 ? '二' : '三' }}餐</div>
          <template v-for="(item1, index2) in item.productsList">
            <div class="food_content customProductListTId zdwPageDiv">
              <div class="food_title">
                {{ index2 + 1 }} {{ item1.productName }}
              </div>
              <div class="food_list">
                <div class="food_left">
                  <div class="food_left_item" v-for="(item2, index2) in item1.effect">
                    <div class="dot"></div>
                    <div class="text_list">{{ item2.effect }}<span v-if="item2.ingredient">:</span>{{
                      item2.ingredient
                    }}
                    </div>
                  </div>
                </div>
                <div class="food_right">
                  <img class="wh100" :src="item1.productPic" alt="">
                </div>
              </div>
            </div>
          </template>
        </div>
      </template>
      <div class="zdwPageDiv" style="font-size: 26px;color: #475057;margin-top: 40px;">
        2、饮食建议
      </div>
      <div class="zdwPageDiv" style="display: flex;justify-content: space-between;">
        <div v-for="(item, index) in objData.singleAnalysisInModel.foodList" :key="index"
          class="analysisReport_box11_content " :style="objData.length == 1 ? 'width:100%;' : 'width:47%;'">
          <div class="analysisReport_box11_content_title">
            <div style="font-size: 40%;">
              {{ item.foodName }}
            </div>
            <div class="a_flex"
              style="width: 30px;height: 30px;border-radius: 50%;color: #fff;background-color: #5aaca9;margin-left: 30px;">
              {{ index + 1 }}
            </div>
          </div>
          <div class="analysisReport_box11_content_img a_flex" style="overflow: hidden;">
            <img style="width: 100%;" :src="item.foodPicture" alt="" />
          </div>
          <div class="analysisReport_box11_content_ul">
            <div class="analysisReport_box11_content_li">
              <div class="analysisReport_box11_content_li_title">
                材料:
              </div>
              <div class="analysisReport_box11_content_li_text">
                {{ item.foodMaterial }}
              </div>
            </div>

            <div class="analysisReport_box11_content_li">
              <div class="analysisReport_box11_content_li_title">
                做法:
              </div>
              <div class="analysisReport_box11_content_li_text">
                <div v-html="item.foodMethod"></div>
              </div>
            </div>
            <div class="analysisReport_box11_content_li">
              <div class="analysisReport_box11_content_li_title">
                注意事项:
              </div>
              <div class="analysisReport_box11_content_li_text">
                {{ item.foodAttention }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import Head from './head.vue'
import Bottom from './bottom.vue'
export default {
  props: ['objData', 'stageHealthyManageList', 'pageNum', 'pageAllNum'],
  data() {
    return {
      showData: false,
      productList: [],
      pageNumA: 0,
      pagesListNum: 1
    }
  },
  methods: {
    chunked(arr) {
      return arr.reduce((accumulator, currentValue, currentIndex) => {
        const chunkIndex = Math.floor(currentIndex / 3)
        if (!accumulator[chunkIndex]) {
          accumulator[chunkIndex] = []
        }
        accumulator[chunkIndex].push(currentValue)
        return accumulator
      }, [])
    },
    removeDuplicatesByName(arr) {
      return arr.map(subArray => {
        // 使用一个Set来存储已经遇到的name值
        const seenNames = new Set()
        // 使用filter来过滤掉重复的对象
        return subArray.filter(obj => {
          if (!seenNames.has(obj.showProductName)) {
            seenNames.add(obj.showProductName)
            return true
          }
          return false
        })
      })
    }
  },
  mounted() {
    setTimeout(() => {
      const domzdwPageDiv = document.getElementsByClassName('zdwPageDiv')
      // console.log('domzdwPageDiv', domzdwPageDiv)
      const cHeight = 150
      let nowPageHeight = cHeight
      let pagesListNum = 1
      for (let i = 0; i < domzdwPageDiv.length; i++) {
        console.log('pagesListNum00', pagesListNum)
        if (nowPageHeight + domzdwPageDiv[i].offsetHeight < 1600 + ((pagesListNum - 1) * 1683)) {
          domzdwPageDiv[i].style.position = 'absolute'
          domzdwPageDiv[i].style.left = '60px'
          domzdwPageDiv[i].style.position = 'absolute'
          domzdwPageDiv[i].style.top = nowPageHeight + 'px'
          nowPageHeight = domzdwPageDiv[i].offsetHeight + 20 + nowPageHeight
        } else {
          pagesListNum = pagesListNum + 1
          nowPageHeight = (pagesListNum - 1) * 1683 + cHeight
          domzdwPageDiv[i].style.position = 'absolute'
          domzdwPageDiv[i].style.left = '60px'
          domzdwPageDiv[i].style.position = 'absolute'
          domzdwPageDiv[i].style.top = nowPageHeight + 'px'
          nowPageHeight = domzdwPageDiv[i].offsetHeight + nowPageHeight
        }
      }
      console.log('pagesListNum33', pagesListNum)
      this.pagesListNum = pagesListNum
    }, 1500)
  },
  watch: {
    objData: {
      immediate: true,
      deep: true, // 开启深度监听 专门对付复杂数据类型
      handler: function (newValueA, oldValue) {

        const masterProductList = newValueA.singleAnalysisInModel.productModel.selfInnovateProduct?.masterProductList
        const factorProductList = newValueA.singleAnalysisInModel.productModel.selfInnovateProduct?.factorProductList
        const otherProductList = newValueA.singleAnalysisInModel.productModel.selfInnovateProduct?.otherProductList
        const masterProductListData = masterProductList || []
        const factorProductListData = factorProductList || []
        const otherProductListData = otherProductList || []
        const productList = [...masterProductListData, ...factorProductListData, ...otherProductListData]
        if (productList.length == 0) {
          this.showData = false
        } else {
          this.showData = true
        }
        const productList1 = []
        // eslint-disable-next-line no-unused-expressions
        productList?.map((v, i) => {
          v.groupProductList.map((v1, i1) => {
            if (i1 == 0) {
              productList1.push({
                showTitle: v.showTitle,
                showSickName: v.showSickName,
                productPicture: v1.productPicture,
                effect: v1.effect,
                showProductName: v1.showProductName,
                unBalanceName: v1.unBalanceName,
                productId: v1.productId,
                showSickName: v1.showSickName
              })
            } else {
              productList1.push({
                showTitle: null,
                showSickName: null,
                productPicture: v1.productPicture,
                effect: v1.effect,
                showProductName: v1.showProductName,
                productId: v1.productId
              })
            }
          })
        })
        const productListRepir = this.chunked(productList1).flat()
        console.log('productListRepir', productListRepir)
        this.productList = productListRepir.reduce((acc, item) => {
          if (!acc[item.productId]) {
            acc[item.productId] = item
          }
          return acc
        }, {})
        console.log('this.productList2222', this.productList)
        setTimeout(() => {
          const getDivHeightBox = document.getElementsByClassName('customProductListTId')
          // console.log('getDivHeightBox', getDivHeightBox)
          let Ttop = 200
          let pageNum = 1
          for (let i = 0; i < getDivHeightBox.length; i++) {
            if (i == 0) {
              Ttop = getDivHeightBox[0].offsetTop
            }
            // console.log('getDivHeightBox[i]', getDivHeightBox[i])
            // console.log('getDivHeightBox[i].offsetHeight', getDivHeightBox[i].offsetHeight)
            if (getDivHeightBox[i].offsetHeight + Ttop < 1600 * pageNum) {
              getDivHeightBox[i].style.position = 'absolute'
              if (getDivHeightBox[i].offsetHeight < 40 && pageNum != 0) {
                getDivHeightBox[i].style.top = Ttop + 50 + 'px'
                Ttop = getDivHeightBox[i].offsetHeight + Ttop + 50
              } else {
                getDivHeightBox[i].style.top = Ttop + 'px'
                Ttop = getDivHeightBox[i].offsetHeight + Ttop + 20
              }
            } else {
              Ttop = (pageNum * 1683) + 150
              pageNum = pageNum + 1
              getDivHeightBox[i].style.position = 'absolute'
              getDivHeightBox[i].style.top = Ttop + 'px'
              Ttop = getDivHeightBox[i].offsetHeight + Ttop + 20
            }
          }
          this.pageNumA = pageNum - 1
          // console.log('getDivHeightBox[0]', getDivHeightBox[0].offsetHeight)
          // console.log('getDivHeightBox[0]', getDivHeightBox[0].offsetTop)
        }, 1)
        setTimeout(() => {
          this.$emit('toAllpage', this.pageNumA)
        }, 3)
      }
    }
  },
  components: {
    Head,
    Bottom
  }
}
</script>

<style lang="scss" scoped>
.analysisReport_box11 {
  width: 974px;
  margin: 0 auto;

  .domzdwPageDiv {
    margin-top: 0 !important;
  }

  .title {
    display: flex;
    font-size: 28px;
    color: #474F57;
    padding-top: 20px;
  }

  .text {
    font-family: Source Han Sans CN;
    font-weight: 400;
    font-size: 20px;
    color: #474F57;
    padding-right: 118px;
    line-height: 36px;
  }

  .food {
    padding-top: 10px;

    .food_num {
      font-size: 24px;
      color: #33AEAA;
      font-weight: 600;
    }

    .food_content {
      width: 950px;
      min-height: 40px;
      border: 1px solid #efefef;
      margin-top: 20px;
      border-radius: 6px;
      padding-bottom: 20px;

      .food_title {
        display: flex;
        align-items: center;
        width: 100%;
        height: 40px;
        background-color: #efefef;
        padding-left: 20px;
        font-size: 18px;
        color: #33AEAA;
      }

      .food_list {
        display: flex;
        justify-content: space-between;

        .food_left {
          padding-left: 20px;
          padding-top: 10px;

          .food_left_item {
            display: flex;
            align-items: center;
            line-height: 22px;

            .dot {
              width: 6px;
              height: 6px;
              border: 1px solid #33AEAA;
              border-radius: 3px;
            }

            .text_list {
              color: #474F57;
              font-size: 14px;
              padding-left: 5px;
            }
          }
        }

        .food_right {
          width: 140px;
          height: 140px;
          margin-top: 10px;
          margin-right: 20px;
          border-radius: 10px;
          overflow: hidden;
        }
      }
    }
  }

  .analysisReport_box11_title {
    display: flex;
    align-items: center;
    margin-top: 65px;

    .analysisReport_box11_title_num {
      font-family: Bahnschrift;
      font-weight: 400;
      font-size: 114px;
      color: #DFE3E8;
    }

    .analysisReport_box11_title_text {
      position: relative;
      top: -15px;
      margin-left: 15px;
      font-weight: 500;
      font-size: 30px;
      color: #5aaca9;

      &::after {
        content: "";
        position: absolute;
        bottom: -24px;
        left: 0px;
        width: 77px;
        height: 2px;
        background: #5aaca9;
        z-index: 1;
      }
    }

  }

  .analysisReport_box11_content {
    margin-top: 20px;

    .analysisReport_box11_content_title {
      display: inline-flex;
      justify-content: space-between;
      align-items: center;
      padding: 13px 30px 14px 35px;
      border-radius: 0 40px 40px 0;
      background-color: #e0f4f3;
      font-weight: 500;
      font-size: 36px;
      color: #5aaca9;
    }

    .analysisReport_box11_content_img {
      margin-top: 43px;
      width: 100%;
      height: 250px;
    }

    .analysisReport_box11_content_ul {
      margin-top: 66px;

      .analysisReport_box11_content_li {
        margin-bottom: 15px;

        .analysisReport_box11_content_li_title {
          position: relative;
          font-size: 28px;
          color: #5aaca9;

          &::after {
            content: "";
            position: absolute;
            bottom: -10px;
            left: 0px;
            width: 77px;
            height: 3px;
            background: #5aaca9;
            z-index: 1;
          }
        }

        .analysisReport_box11_content_li_text {
          margin-top: 15px;
          font-size: 20px;
        }
      }
    }
  }
}

.analysisReport_box11_S6_ul {
  margin-top: 20px;

  .analysisReport_box11_S6_li {
    display: flex;
    align-items: center;
    margin-top: 10px;
    font-size: 20px;

    .analysisReport_box11_S6_li_A {
      display: flex;
      align-items: center;
      width: 200px;
      height: 50px;
      margin-right: 10px;
    }

    .analysisReport_box11_S6_li_B {
      display: flex;
      align-items: center;
      width: 280px;
      height: 39px;
      border-radius: 15px;
      border: 1px solid red;
      margin-right: 10px;

      .analysisReport_box11_S6_li_B_d {
        margin: 0 13px 0 13px;
        width: 7px;
        height: 7px;
        background: #0d1720;
        border-radius: 50%;
      }
    }
  }

}

.productTitle {
  padding: 10px 0;
  padding-left: 104px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  font-size: 32px;
  color: #2baea9;
}

.productContent {
  display: flex;
  justify-content: space-between;

  .productContent_left {
    font-family: Source Han Sans CN;
    font-weight: 400;
    font-size: 18px;
    color: #474F57;

    .productContent_left_name {
      font-family: Source Han Sans CN;
      font-weight: 400;
      font-size: 22px;
      color: #474F57;
    }

    .productContent_line {
      margin: 10px 0;
      margin-bottom: 20px;
      width: 600px;
      height: 0.5px;
      border: 0.5px solid #2baea9;
      opacity: 0.1;
    }

    .productContent_left_effect {
      display: flex;
      align-items: center;
      padding: 2px 0;

      .effect_img {
        width: 18px;
        height: 18px
      }

      .effect_text {
        padding-left: 10px;
        font-size: 13px;
        color: #474F57;
      }
    }

  }

  .productContent_right {
    overflow: hidden;
    width: 140px;
    height: 140px;
    background: #FFFFFF;
    border-radius: 27px;
    border: 4px solid #C6E5DF;
    margin-right: 87px;

    .img {
      width: 100%;
      height: 100%;
    }
  }
}

.analysisReport_box11 {
  width: 974px;
  margin: 0 auto;

  .analysisReport_box11_title {
    display: flex;
    align-items: center;
    margin-top: 65px;

    .analysisReport_box11_title_num {
      font-family: Bahnschrift;
      font-weight: 400;
      font-size: 114px;
      color: #DFE3E8;
    }

    .analysisReport_box11_title_text {
      position: relative;
      top: -15px;
      margin-left: 15px;
      font-weight: 500;
      font-size: 42px;
      color: #5aaca9;

      &::after {
        content: "";
        position: absolute;
        bottom: -24px;
        left: 0px;
        width: 77px;
        height: 2px;
        background: #5aaca9;
        z-index: 1;
      }
    }

  }

  .analysisReport_box11_content {
    margin-top: 20px;

    .analysisReport_box11_content_title {
      display: inline-flex;
      justify-content: space-between;
      align-items: center;
      padding: 3px 20px 3px 25px;
      border-radius: 0 40px 40px 0;
      background-color: #e0f4f3;
      font-weight: 500;
      font-size: 36px;
      color: #5aaca9;
    }

    .analysisReport_box11_content_img {
      margin-top: 43px;
      width: 100%;
      height: 250px;
    }

    .analysisReport_box11_content_ul {
      margin-top: 26px;

      .analysisReport_box11_content_li {
        margin-bottom: 15px;

        .analysisReport_box11_content_li_title {
          position: relative;
          font-size: 18px;
          color: #5aaca9;

          &::after {
            content: "";
            position: absolute;
            bottom: -10px;
            left: 0px;
            width: 77px;
            height: 3px;
            background: #5aaca9;
            z-index: 1;
          }
        }

        .analysisReport_box11_content_li_text {
          margin-top: 15px;
          font-size: 16px;
          color: #474F57;
        }
      }
    }
  }
}

.analysisReport_box11_S6_ul {
  margin-top: 20px;

  .analysisReport_box11_S6_li {
    display: flex;
    align-items: center;
    margin-top: 10px;
    font-size: 20px;

    .analysisReport_box11_S6_li_A {
      display: flex;
      align-items: center;
      width: 200px;
      height: 50px;
      margin-right: 10px;
    }

    .analysisReport_box11_S6_li_B {
      display: flex;
      align-items: center;
      width: 230px;
      height: 59px;
      border-radius: 15px;
      border: 1px solid red;
      margin-right: 10px;

      .analysisReport_box11_S6_li_B_d {
        margin: 0 13px 0 23px;
        width: 7px;
        height: 7px;
        background: #0d1720;
        border-radius: 50%;
      }
    }
  }
}

.food {
  padding-top: 10px;

  .food_num {
    font-size: 24px;
    color: #33AEAA;
    font-weight: 600;
  }

  .food_content {
    width: 950px;
    min-height: 40px;
    border: 1px solid #efefef;
    margin-top: 20px;
    border-radius: 6px;
    padding-bottom: 20px;

    .food_title {
      display: flex;
      align-items: center;
      width: 100%;
      height: 40px;
      background-color: #efefef;
      padding-left: 20px;
      font-size: 18px;
      color: #33AEAA;
    }

    .food_list {
      display: flex;
      justify-content: space-between;

      .food_left {
        padding-left: 20px;
        padding-top: 10px;

        .food_left_item {
          display: flex;
          align-items: center;
          line-height: 22px;

          .dot {
            width: 6px;
            height: 6px;
            border: 1px solid #33AEAA;
            border-radius: 3px;
          }

          .text_list {
            color: #474F57;
            font-size: 14px;
            padding-left: 5px;
          }
        }
      }

      .food_right {
        width: 140px;
        height: 140px;
        margin-top: 10px;
        margin-right: 20px;
        border-radius: 10px;
        overflow: hidden;
      }
    }
  }
}
</style>
