<template>
  <div class="pageSize" v-if="objData" style="position: relative">
    <Head text="健康管理方案" bgColor="#5aaca9"></Head>
    <div class="analysisReport_box11">
      <div style="font-size: 26px; color: #475057; margin-top: 40px">
        营养建议
      </div>
      <div style="display: flex; justify-content: space-between">
        <div
          v-for="(item, index) in objData.singleAnalysisInModel.foodList"
          :key="index"
          class="analysisReport_box11_content"
          :style="objData.length == 1 ? 'width:100%;' : 'width:47%;'"
        >
          <div class="analysisReport_box11_content_title">
            <div style="font-size: 40%">
              {{ item.foodName }}
            </div>
            <div
              class="a_flex"
              style="
                width: 30px;
                height: 30px;
                border-radius: 50%;
                color: #fff;
                background-color: #5aaca9;
                margin-left: 30px;
              "
            >
              {{ index + 1 }}
            </div>
          </div>
          <div
            class="analysisReport_box11_content_img a_flex"
            style="overflow: hidden"
          >
            <img style="width: 100%" :src="item.foodPicture" alt="" />
          </div>
          <div class="analysisReport_box11_content_ul">
            <div class="analysisReport_box11_content_li">
              <div class="analysisReport_box11_content_li_title">材料:</div>
              <div class="analysisReport_box11_content_li_text">
                {{ item.foodMaterial }}
              </div>
            </div>

            <div class="analysisReport_box11_content_li">
              <div class="analysisReport_box11_content_li_title">做法:</div>
              <div class="analysisReport_box11_content_li_text">
                <div v-html="item.foodMethod"></div>
              </div>
            </div>
            <div class="analysisReport_box11_content_li">
              <div class="analysisReport_box11_content_li_title">注意事项:</div>
              <div class="analysisReport_box11_content_li_text">
                {{ item.foodAttention }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="analysisReportPage"
      style="
        position: absolute;
        right: 60px;
        bottom: 60px;
        font-size: 20px;
        color: #000;
      "
    >
      {{ pageAllNum }} of {{ pageAllNum }}
    </div>
  </div>
</template>

<script>
import Head from "./head.vue";
export default {
  props: ["objData", "stageHealthyManageList", "pageNum", "pageAllNum"],
  data() {
    return {
      showData: false,
      productList: [],
      pageNumA: 0,
      pagesListNum: 1,
    };
  },
  watch: {
    objData: {
      immediate: true,
      deep: true, // 开启深度监听 专门对付复杂数据类型
      handler: function (newValueA, oldValue) {},
    },
  },
  mounted () {
    setTimeout(() => {
      const analysisReportPage =
        document.getElementsByClassName("analysisReportPage");
      this.$emit("toAllpageF", analysisReportPage.length);
    }, 3);
  },
  components: {
    Head,
  },
};
</script>

<style lang="scss" scoped>
.analysisReport_box11 {
  width: 974px;
  margin: 0 auto;

  .domzdwPageDiv {
    margin-top: 0 !important;
  }

  .title {
    display: flex;
    font-size: 28px;
    color: #474f57;
    padding-top: 20px;
  }

  .text {
    font-family: Source Han Sans CN;
    font-weight: 400;
    font-size: 20px;
    color: #474f57;
    padding-right: 118px;
    line-height: 36px;
  }

  .food {
    padding-top: 10px;

    .food_num {
      font-size: 24px;
      color: #33aeaa;
      font-weight: 600;
    }

    .food_content {
      width: 950px;
      min-height: 40px;
      border: 1px solid #efefef;
      margin-top: 20px;
      border-radius: 6px;
      padding-bottom: 20px;

      .food_title {
        display: flex;
        align-items: center;
        width: 100%;
        height: 40px;
        background-color: #efefef;
        padding-left: 20px;
        font-size: 18px;
        color: #33aeaa;
      }

      .food_list {
        display: flex;
        justify-content: space-between;

        .food_left {
          padding-left: 20px;
          padding-top: 10px;

          .food_left_item {
            display: flex;
            align-items: center;
            line-height: 22px;

            .dot {
              width: 6px;
              height: 6px;
              border: 1px solid #33aeaa;
              border-radius: 3px;
            }

            .text_list {
              color: #474f57;
              font-size: 14px;
              padding-left: 5px;
            }
          }
        }

        .food_right {
          width: 140px;
          height: 140px;
          margin-top: 10px;
          margin-right: 20px;
          border-radius: 10px;
          overflow: hidden;
        }
      }
    }
  }

  .analysisReport_box11_title {
    display: flex;
    align-items: center;
    margin-top: 65px;

    .analysisReport_box11_title_num {
      font-family: Bahnschrift;
      font-weight: 400;
      font-size: 114px;
      color: #dfe3e8;
    }

    .analysisReport_box11_title_text {
      position: relative;
      top: -15px;
      margin-left: 15px;
      font-weight: 500;
      font-size: 30px;
      color: #5aaca9;

      &::after {
        content: "";
        position: absolute;
        bottom: -24px;
        left: 0px;
        width: 77px;
        height: 2px;
        background: #5aaca9;
        z-index: 1;
      }
    }
  }

  .analysisReport_box11_content {
    margin-top: 20px;

    .analysisReport_box11_content_title {
      display: inline-flex;
      justify-content: space-between;
      align-items: center;
      padding: 13px 30px 14px 35px;
      border-radius: 0 40px 40px 0;
      background-color: #e0f4f3;
      font-weight: 500;
      font-size: 36px;
      color: #5aaca9;
    }

    .analysisReport_box11_content_img {
      margin-top: 43px;
      width: 100%;
      height: 250px;
    }

    .analysisReport_box11_content_ul {
      margin-top: 66px;

      .analysisReport_box11_content_li {
        margin-bottom: 15px;

        .analysisReport_box11_content_li_title {
          position: relative;
          font-size: 28px;
          color: #5aaca9;

          &::after {
            content: "";
            position: absolute;
            bottom: -10px;
            left: 0px;
            width: 77px;
            height: 3px;
            background: #5aaca9;
            z-index: 1;
          }
        }

        .analysisReport_box11_content_li_text {
          margin-top: 15px;
          font-size: 20px;
        }
      }
    }
  }
}

.analysisReport_box11_S6_ul {
  margin-top: 20px;

  .analysisReport_box11_S6_li {
    display: flex;
    align-items: center;
    margin-top: 10px;
    font-size: 20px;

    .analysisReport_box11_S6_li_A {
      display: flex;
      align-items: center;
      width: 200px;
      height: 50px;
      margin-right: 10px;
    }

    .analysisReport_box11_S6_li_B {
      display: flex;
      align-items: center;
      width: 280px;
      height: 39px;
      border-radius: 15px;
      border: 1px solid red;
      margin-right: 10px;

      .analysisReport_box11_S6_li_B_d {
        margin: 0 13px 0 13px;
        width: 7px;
        height: 7px;
        background: #0d1720;
        border-radius: 50%;
      }
    }
  }
}

.productTitle {
  padding: 10px 0;
  padding-left: 104px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  font-size: 32px;
  color: #2baea9;
}

.productContent {
  display: flex;
  justify-content: space-between;

  .productContent_left {
    font-family: Source Han Sans CN;
    font-weight: 400;
    font-size: 18px;
    color: #474f57;

    .productContent_left_name {
      font-family: Source Han Sans CN;
      font-weight: 400;
      font-size: 22px;
      color: #474f57;
    }

    .productContent_line {
      margin: 10px 0;
      margin-bottom: 20px;
      width: 600px;
      height: 0.5px;
      border: 0.5px solid #2baea9;
      opacity: 0.1;
    }

    .productContent_left_effect {
      display: flex;
      align-items: center;
      padding: 2px 0;

      .effect_img {
        width: 18px;
        height: 18px;
      }

      .effect_text {
        padding-left: 10px;
        font-size: 13px;
        color: #474f57;
      }
    }
  }

  .productContent_right {
    overflow: hidden;
    width: 140px;
    height: 140px;
    background: #ffffff;
    border-radius: 27px;
    border: 4px solid #c6e5df;
    margin-right: 87px;

    .img {
      width: 100%;
      height: 100%;
    }
  }
}

.analysisReport_box11 {
  width: 974px;
  margin: 0 auto;

  .analysisReport_box11_title {
    display: flex;
    align-items: center;
    margin-top: 65px;

    .analysisReport_box11_title_num {
      font-family: Bahnschrift;
      font-weight: 400;
      font-size: 114px;
      color: #dfe3e8;
    }

    .analysisReport_box11_title_text {
      position: relative;
      top: -15px;
      margin-left: 15px;
      font-weight: 500;
      font-size: 42px;
      color: #5aaca9;

      &::after {
        content: "";
        position: absolute;
        bottom: -24px;
        left: 0px;
        width: 77px;
        height: 2px;
        background: #5aaca9;
        z-index: 1;
      }
    }
  }

  .analysisReport_box11_content {
    margin-top: 20px;

    .analysisReport_box11_content_title {
      display: inline-flex;
      justify-content: space-between;
      align-items: center;
      padding: 3px 20px 3px 25px;
      border-radius: 0 40px 40px 0;
      background-color: #e0f4f3;
      font-weight: 500;
      font-size: 36px;
      color: #5aaca9;
    }

    .analysisReport_box11_content_img {
      margin-top: 43px;
      width: 100%;
      height: 250px;
    }

    .analysisReport_box11_content_ul {
      margin-top: 26px;

      .analysisReport_box11_content_li {
        margin-bottom: 15px;

        .analysisReport_box11_content_li_title {
          position: relative;
          font-size: 18px;
          color: #5aaca9;

          &::after {
            content: "";
            position: absolute;
            bottom: -10px;
            left: 0px;
            width: 77px;
            height: 3px;
            background: #5aaca9;
            z-index: 1;
          }
        }

        .analysisReport_box11_content_li_text {
          margin-top: 15px;
          font-size: 16px;
          color: #474f57;
        }
      }
    }
  }
}

.analysisReport_box11_S6_ul {
  margin-top: 20px;

  .analysisReport_box11_S6_li {
    display: flex;
    align-items: center;
    margin-top: 10px;
    font-size: 20px;

    .analysisReport_box11_S6_li_A {
      display: flex;
      align-items: center;
      width: 200px;
      height: 50px;
      margin-right: 10px;
    }

    .analysisReport_box11_S6_li_B {
      display: flex;
      align-items: center;
      width: 230px;
      height: 59px;
      border-radius: 15px;
      border: 1px solid red;
      margin-right: 10px;

      .analysisReport_box11_S6_li_B_d {
        margin: 0 13px 0 23px;
        width: 7px;
        height: 7px;
        background: #0d1720;
        border-radius: 50%;
      }
    }
  }
}

.food {
  padding-top: 10px;

  .food_num {
    font-size: 24px;
    color: #33aeaa;
    font-weight: 600;
  }

  .food_content {
    width: 950px;
    min-height: 40px;
    border: 1px solid #efefef;
    margin-top: 20px;
    border-radius: 6px;
    padding-bottom: 20px;

    .food_title {
      display: flex;
      align-items: center;
      width: 100%;
      height: 40px;
      background-color: #efefef;
      padding-left: 20px;
      font-size: 18px;
      color: #33aeaa;
    }

    .food_list {
      display: flex;
      justify-content: space-between;

      .food_left {
        padding-left: 20px;
        padding-top: 10px;

        .food_left_item {
          display: flex;
          align-items: center;
          line-height: 22px;

          .dot {
            width: 6px;
            height: 6px;
            border: 1px solid #33aeaa;
            border-radius: 3px;
          }

          .text_list {
            color: #474f57;
            font-size: 14px;
            padding-left: 5px;
          }
        }
      }

      .food_right {
        width: 140px;
        height: 140px;
        margin-top: 10px;
        margin-right: 20px;
        border-radius: 10px;
        overflow: hidden;
      }
    }
  }
}
</style>
